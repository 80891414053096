"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PaymentStatus = exports.PaymentType = void 0;
var PaymentType;
exports.PaymentType = PaymentType;

(function (PaymentType) {
  PaymentType["Cash"] = "CASH";
  PaymentType["Point"] = "POINT";
  PaymentType["VNPay"] = "VNPAY";
})(PaymentType || (exports.PaymentType = PaymentType = {}));

var PaymentStatus;
exports.PaymentStatus = PaymentStatus;

(function (PaymentStatus) {
  PaymentStatus["Pending"] = "PENDING";
  PaymentStatus["Complete"] = "COMPLETE";
  PaymentStatus["Fail"] = "FAIL";
})(PaymentStatus || (exports.PaymentStatus = PaymentStatus = {}));