var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        [
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("customer") } },
            [_c("Reward", { attrs: { rewardType: _vm.RewardType.Customer } })],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("driver") } },
            [_c("Reward", { attrs: { rewardType: _vm.RewardType.Driver } })],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("system") } },
            [_c("Reward", { attrs: { rewardType: _vm.RewardType.System } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }