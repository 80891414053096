"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatDate = formatDate;
exports.formatDateTime = formatDateTime;
exports.formatDateDay = formatDateDay;

var _moment = _interopRequireDefault(require("moment"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

// @ts-check
var locale = _jsCookie.default.get("language") || "en";

function formatDate(timestamp) {
  if (!timestamp) {
    return "--";
  }

  return _moment.default.unix(timestamp).format("DD/MM/YYYY");
}

function formatDateTime(timestamp) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "HH:mm:ss, DD/MM/YYYY";

  if (!timestamp) {
    return "--";
  }

  return _moment.default.unix(timestamp).format(format);
}

function formatDateDay(timestamp) {
  return _moment.default.unix(timestamp).format("dddd, MM/DD/YYYY");
}