var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.dialogStatus == "update" ? _vm.$t("update") : _vm.$t("add"),
        visible: _vm.dialogVisible,
        width: "1200px",
        top: "15px"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading"
            }
          ]
        },
        [
          _c("news-form", {
            ref: "newsFrom",
            attrs: {
              beforeUpload: _vm.beforeUpload,
              formData: _vm.formData,
              handleInitTinymce: _vm.handleInitTinymce,
              handleUploadSuccess: _vm.handleUploadSuccess,
              rules: _vm.rules
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("Đóng")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.submitLoading, type: "primary" },
              on: {
                click: function($event) {
                  _vm.dialogStatus == "create"
                    ? _vm.createData()
                    : _vm.updateData()
                }
              }
            },
            [_vm._v("Đồng ý")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }