"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PaymentTypeTrans = exports.PaymentType = exports.OrderStatusColor = exports.OrderStatusTrans = exports.OrderStatus = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _OrderStatusTrans, _OrderStatusColor, _PaymentTypeTrans;

var OrderStatus = {
  Pending: "PENDING",
  Delivering: "DELIVERING",
  Complete: "COMPLETE",
  Cancel: "CANCEL"
};
exports.OrderStatus = OrderStatus;
var OrderStatusTrans = (_OrderStatusTrans = {}, (0, _defineProperty2.default)(_OrderStatusTrans, OrderStatus.Pending, "Chờ duyệt"), (0, _defineProperty2.default)(_OrderStatusTrans, OrderStatus.Delivering, "Đang di chuyển"), (0, _defineProperty2.default)(_OrderStatusTrans, OrderStatus.Complete, "Hoàn thành"), (0, _defineProperty2.default)(_OrderStatusTrans, OrderStatus.Cancel, "Huỷ"), _OrderStatusTrans);
exports.OrderStatusTrans = OrderStatusTrans;
var OrderStatusColor = (_OrderStatusColor = {}, (0, _defineProperty2.default)(_OrderStatusColor, OrderStatus.Pending, "warning"), (0, _defineProperty2.default)(_OrderStatusColor, OrderStatus.Delivering, "brand"), (0, _defineProperty2.default)(_OrderStatusColor, OrderStatus.Complete, "success"), (0, _defineProperty2.default)(_OrderStatusColor, OrderStatus.Cancel, "danger"), _OrderStatusColor);
exports.OrderStatusColor = OrderStatusColor;
var PaymentType;
exports.PaymentType = PaymentType;

(function (PaymentType) {
  PaymentType["Cash"] = "CASH";
  PaymentType["Point"] = "POINT";
  PaymentType["MOMO"] = "MOMO";
})(PaymentType || (exports.PaymentType = PaymentType = {}));

var PaymentTypeTrans = (_PaymentTypeTrans = {}, (0, _defineProperty2.default)(_PaymentTypeTrans, PaymentType.Cash, 'Tiền mặt'), (0, _defineProperty2.default)(_PaymentTypeTrans, PaymentType.Point, 'Điểm'), (0, _defineProperty2.default)(_PaymentTypeTrans, PaymentType.MOMO, 'Momo'), _PaymentTypeTrans);
exports.PaymentTypeTrans = PaymentTypeTrans;