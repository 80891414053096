var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        top: "30px",
        title:
          _vm.dialogStatus == "add"
            ? _vm.$t("add") + " " + _vm.$t("customer")
            : _vm.$t("update") + " " + _vm.$t("customer"),
        visible: _vm.dialogVisible,
        width: _vm.dialogWidth,
        "close-on-click-modal": false,
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading"
            }
          ]
        },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              model: {
                value: _vm.tabName,
                callback: function($$v) {
                  _vm.tabName = $$v
                },
                expression: "tabName"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: _vm.$t("info"), name: "info" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "dataForm",
                      attrs: {
                        disabled: _vm.dialogDisabled,
                        rules: _vm.rules,
                        model: _vm.tempData,
                        "label-position": "top"
                      }
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("fullname"),
                                    prop: "name"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.tempData.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.tempData, "name", $$v)
                                      },
                                      expression: "tempData.name"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("address"),
                                    prop: "address"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.tempData.address,
                                      callback: function($$v) {
                                        _vm.$set(_vm.tempData, "address", $$v)
                                      },
                                      expression: "tempData.address"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("phone"),
                                    prop: "phone"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.tempData.phone,
                                      callback: function($$v) {
                                        _vm.$set(_vm.tempData, "phone", $$v)
                                      },
                                      expression: "tempData.phone"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("phoneRelative"),
                                    prop: "phoneRelative"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.tempData.phoneRelative,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.tempData,
                                          "phoneRelative",
                                          $$v
                                        )
                                      },
                                      expression: "tempData.phoneRelative"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Email", prop: "email" } },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.tempData.email,
                                      callback: function($$v) {
                                        _vm.$set(_vm.tempData, "email", $$v)
                                      },
                                      expression: "tempData.email"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("createdAt"),
                                    prop: "dateCreated"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      value: _vm.formatDateTime(
                                        _vm.tempData.createdAt
                                      ),
                                      disabled: ""
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("transactionHistory"),
                    name: "Transaction History"
                  }
                },
                [
                  _c("TransactionHistory", {
                    attrs: { customerId: _vm.tempData.id }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("close")))]
          ),
          _vm._v(" "),
          !_vm.dialogDisabled
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: {
                    click: function($event) {
                      _vm.dialogStatus == "edit"
                        ? _vm.editData()
                        : _vm.createData()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("accept")))]
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }