var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c("panel-group"),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 32 } },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("CustomerChart")],
              1
            )
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("TransportChart")],
              1
            )
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("OrderHandleOverChart")],
              1
            )
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("OrderLongTermChart")],
              1
            )
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("OrderShoppingChart")],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 32 } },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("div", { staticClass: "chart-wrapper" }, [_c("Top5Driver")], 1)
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("div", { staticClass: "chart-wrapper" }, [_c("Top5Customer")], 1)
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }