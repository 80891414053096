"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.number.constructor");

var _orderHandleOver = require("@/api/order-handle-over");

var driver = _interopRequireWildcard(require("@/api/driver"));

var _date = require("@/utils/date");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _format = require("@/utils/format");

var _enum = require("@/utils/enum");

var _CustomerDialog = _interopRequireDefault(require("@/views/customer/components/CustomerDialog"));

var _moment = _interopRequireDefault(require("moment"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "OrderCarDelivery",
  components: {
    Pagination: _Pagination.default,
    CustomerDialog: _CustomerDialog.default
  },
  props: {
    driverId: {
      type: Number,
      default: null
    },
    showStatusCol: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      date: [// moment()
        //   .subtract(7, "days")
        //   .format("YYYY-MM-DD"),
        // moment().format("YYYY-MM-DD"),
      ],
      totalRevenue: 0,
      totalIncome: 0,
      totalMoney: 0,
      ORDER_DELIVERY_STATUS: _enum.ORDER_DELIVERY_STATUS,
      listLoading: false,
      total: 0,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        status: null,
        isBlock: null
      },
      tableData: [],
      tagOptions: _enum.tagOptions
    };
  },
  watch: {
    driverId: {
      handler: function handler() {
        this.date = [];
        this.getTableDataByDriverId();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    viewCustomer: function viewCustomer(customer) {
      this.$refs["customer-dialog"].handleEdit(customer);
    },
    viewDriver: function viewDriver(driver) {
      this.$refs["driver-dialog"].handleEdit(driver);
    },
    getTableDataByDriverId: function getTableDataByDriverId() {
      var _this = this;

      this.listLoading = true;
      this.listQuery.from = this.date ? this.date[0] : null;
      this.listQuery.to = this.date ? this.date[1] : null;

      _orderHandleOver.orderHandleOverApi.findAll((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.listQuery), {}, {
        driverId: this.driverId
      })).then(function (res) {
        _this.tableData = res.data.data;
        _this.total = res.data.total;
        _this.totalRevenue = res.data.totalRevenue;
        _this.totalIncome = res.data.totalIncome;
      }).finally(function () {
        _this.listLoading = false;
      });
    },
    formatNumberVN: function formatNumberVN(n) {
      return (0, _format.formatNumberVN)(n);
    },
    formatCurrency: function formatCurrency(n) {
      return (0, _format.formatCurrency)(n);
    },
    changeStatus: function changeStatus(row) {
      var _this2 = this;

      this.listLoading = true;
      var data = {
        orderTransport: {
          status: row.status
        }
      };

      _orderHandleOver.orderHandleOverApi.update(row.id, data).then(function () {
        _this2.$notify({
          type: "success",
          message: "Thành công"
        });

        _this2.getTableDataByDriverId();
      }).finally(function () {
        _this2.listLoading = false;
      });
    },
    formatDateTime: function formatDateTime(timestamp) {
      return (0, _date.formatDateTime)(timestamp);
    }
  }
};
exports.default = _default;