"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _OrderCarDelivery = _interopRequireDefault(require("./OrderCarDelivery.vue"));

var _OrderLongterm = _interopRequireDefault(require("./OrderLongterm.vue"));

var _OrderTransport = _interopRequireDefault(require("./OrderTransport"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    driverId: Number
  },
  components: {
    OrderTransport: _OrderTransport.default,
    OrderLongterm: _OrderLongterm.default,
    OrderCarDelivery: _OrderCarDelivery.default
  }
};
exports.default = _default;