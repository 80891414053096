"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.OrderTransportStatus = void 0;

require("core-js/modules/web.dom.iterable");

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.number.constructor");

var order = _interopRequireWildcard(require("@/api/order/transport"));

var driver = _interopRequireWildcard(require("@/api/driver"));

var _date = require("@/utils/date");

var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));

var _format = require("@/utils/format");

var _enum = require("@/utils/enum");

var _CustomerDialog = _interopRequireDefault(require("@/views/customer/components/CustomerDialog.vue"));

var _DriverDialog = _interopRequireDefault(require("@/views/driver/components/DriverDialog.vue"));

var _ImagesOrder = _interopRequireDefault(require("./ImagesOrder.vue"));

var _exportOrderExcel = require("./export-order-excel");

var _constant = require("@/constant");

var OrderTransportStatus = {
  FIND_DRIVER: "Tìm t.xế",
  ACCEPT_ORDER: "Chấp nhận đơn",
  NOT_FOUND_DRIVER: "Không tìm thấy t.xế",
  DELIVERING: "Đang đi",
  COMPLETE: "Hoàn thành",
  DRIVER_CANCEL: "T.xế hủy",
  CUSTOMER_CANCEL: "Khách hủy",
  SCHEDULE: "Đặt lịch (Mới)",
  PENDING_PAYMENT: "Chờ thanh toán",
  ADMIN_CANCEL: "Admin hủy"
};
exports.OrderTransportStatus = OrderTransportStatus;
var _default = {
  name: "OrderTransport",
  components: {
    Pagination: _index.default,
    CustomerDialog: _CustomerDialog.default,
    ImagesOrder: _ImagesOrder.default,
    DriverDialog: _DriverDialog.default
  },
  props: {
    driverId: {
      type: Number,
      default: null
    },
    showStatusCol: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      PaymentType: _constant.PaymentType,
      PaymentStatus: _constant.PaymentStatus,
      date: [],
      CarType: _enum.CarType,
      OrderTransportStatus: OrderTransportStatus,
      totalRevenue: 0,
      totalIncome: 0,
      totalMoney: 0,
      ORDER_DELIVERY_STATUS: _enum.ORDER_DELIVERY_STATUS,
      listLoading: false,
      total: 0,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        status: null,
        isBlock: null,
        paymentType: null,
        paymentStatus: null
      },
      tableData: [],
      tagOptions: _enum.tagOptions,
      exportLoading: false
    };
  },
  computed: {
    visibleCancel: function visibleCancel() {
      return this.$store.state.permission.routes.some(function (e) {
        return e.path == "/order/transport/cancel";
      });
    }
  },
  created: function created() {
    if (!this.driverId) {
      this.getTableData();
    } else {
      this.getTableDataByDriverId();
    }
  },
  watch: {
    driverId: function driverId() {
      this.getTableDataByDriverId();
    }
  },
  methods: {
    exportExcel: function exportExcel() {
      var _this = this;

      this.exportLoading = true;
      var query = (0, _objectSpread2.default)({}, this.listQuery);
      this.listQuery.from = this.date ? this.date[0] : null;
      this.listQuery.to = this.date ? this.date[1] : null;
      delete query.page;
      delete query.limit;
      order.findAll(query).then(function (res) {
        (0, _exportOrderExcel.exportOrderExcel)({
          data: res.data.data
        });
      }).finally(function () {
        _this.exportLoading = false;
      });
    },
    handleView: function handleView(order) {
      var rate = {
        rateComment: order.rateComment,
        rateCommentCustomer: order.rateCommentCustomer,
        rateStar: order.rateStar,
        rateStarCustomer: order.rateStarCustomer
      };
      this.$refs.ImagesOrder.handleOpen(order, rate);
    },
    viewCustomer: function viewCustomer(customer) {
      this.$refs["customer-dialog"].handleEdit(customer);
    },
    viewDriver: function viewDriver(driver) {
      this.$refs["driver-dialog"].handleEdit(driver);
    },
    getTableDataByDriverId: function getTableDataByDriverId() {
      var _this2 = this;

      driver.orderDelivery(this.driverId, this.listQuery).then(function (res) {
        _this2.tableData = res.data.data;
        _this2.total = res.data.total;
      }).finally(function () {
        _this2.listLoading = false;
      });
    },
    formatNumberVN: function formatNumberVN(n) {
      return (0, _format.formatNumberVN)(n);
    },
    formatCurrency: function formatCurrency(n) {
      return (0, _format.formatCurrency)(n);
    },
    changeStatus: function changeStatus(row) {
      var _this3 = this;

      this.$prompt("Nhập lý do", "Đơn này sẽ bị huỷ và đơn hàng này không được tính vào doanh thu của hệ thống?", {
        type: "warning",
        cancelButtonText: "Đóng",
        confirmButtonText: "OK"
      }).then(function (_ref) {
        var value = _ref.value;
        order.cancel(row.id, {
          reason: value
        }).then(function () {
          _this3.$notify({
            type: "success",
            title: "Thông báo",
            message: "Thành công"
          });

          _this3.getTableData();
        });
      });
    },
    adminCancel: function adminCancel(row) {
      var _this4 = this;

      this.$prompt("Nhập lý do", "Đơn này sẽ bị huỷ và đơn hàng này không được tính vào doanh thu của hệ thống?", {
        type: "warning",
        cancelButtonText: "Đóng",
        confirmButtonText: "OK"
      }).then(function (_ref2) {
        var value = _ref2.value;
        order.adminCancel(row.id, {
          reason: value
        }).then(function () {
          _this4.$notify({
            type: "success",
            title: "Thông báo",
            message: "Thành công"
          });

          _this4.getTableData();
        });
      });
    },
    getTotalMoney: function getTotalMoney() {
      var _this5 = this;

      var listQuery = {
        from: this.listQuery.from,
        to: this.listQuery.to
      };
      var data = [];
      order.findAll(listQuery).then(function (res) {
        data = res.data.data;
        var total = 0;
        data.forEach(function (element) {
          if (element.status == "COMPLETE") total += element.moneyFinal - element.moneyIncome;
        });
        _this5.totalMoney = total;
      });
    },
    getTableData: function getTableData() {
      var _this6 = this;

      this.listLoading = true;
      this.listQuery.from = this.date ? this.date[0] : null;
      this.listQuery.to = this.date ? this.date[1] : null;
      order.findAll(this.listQuery).then(function (res) {
        _this6.tableData = res.data.data;
        _this6.total = res.data.total;
        _this6.totalRevenue = res.data.totalRevenue;
        _this6.totalIncome = res.data.totalIncome;
      }).finally(function () {
        _this6.listLoading = false;
      });
    },
    formatDateTime: function formatDateTime(timestamp) {
      return (0, _date.formatDateTime)(timestamp);
    }
  }
};
exports.default = _default;