var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.$t("search")))
              ]),
              _vm._v(" "),
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: {
                  "prefix-icon": "el-icon-search",
                  placeholder: _vm.$t("orderTransportPlaceholder")
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    _vm.listQuery.page = 1
                    _vm.getTableData()
                  }
                },
                model: {
                  value: _vm.listQuery.search,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "search", $$v)
                  },
                  expression: "listQuery.search"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.$t("chooseDate")))
              ]),
              _vm._v(" "),
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "To",
                  format: "dd/MM/yyyy",
                  "value-format": "yyyy-MM-dd",
                  "start-placeholder": _vm.$t("startDate"),
                  "end-placeholder": _vm.$t("endDate")
                },
                on: {
                  change: function($event) {
                    _vm.listQuery.page = 1
                    _vm.getTableData()
                  }
                },
                model: {
                  value: _vm.date,
                  callback: function($$v) {
                    _vm.date = $$v
                  },
                  expression: "date"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.$t("status")))
              ]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { clearable: "" },
                  on: {
                    change: function($event) {
                      _vm.listQuery.page = 1
                      _vm.getTableData()
                    }
                  },
                  model: {
                    value: _vm.listQuery.status,
                    callback: function($$v) {
                      _vm.$set(_vm.listQuery, "status", $$v)
                    },
                    expression: "listQuery.status"
                  }
                },
                _vm._l(_vm.OrderHandleOverStatusTrans, function(label, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: _vm.$t(key), value: key }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-top": "20px" },
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function($event) {
                  _vm.listQuery.page = 1
                  _vm.getTableData()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("find")))]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-top": "20px" },
              attrs: {
                type: "primary",
                icon: "el-icon-download",
                loading: _vm.exportLoading
              },
              on: { click: _vm.exportExcel }
            },
            [_vm._v(_vm._s(_vm.$t("exportExcel")))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: { data: _vm.tableData, border: "", fit: "" }
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("order"), prop: "code", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticStyle: { "padding-left": "10px" } }, [
                      _c(
                        "li",
                        [
                          _c("b", [_vm._v(_vm._s(row.code))]),
                          _vm._v(" "),
                          _c(
                            "el-tag",
                            {
                              attrs: {
                                size: "mini",
                                type:
                                  _vm.tagOptions[row.status] &&
                                  _vm.tagOptions[row.status].type,
                                effect:
                                  _vm.tagOptions[row.status] &&
                                  _vm.tagOptions[row.status].effect
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t(row.status)))]
                          ),
                          _vm._v(" "),
                          row.status == _vm.OrderHandleOverStatus.Complete &&
                          row.rateStar
                            ? _c("el-rate", {
                                attrs: { value: row.rateStar, disabled: "" }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v(_vm._s(_vm.$t("customer")) + ":")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            !row.customer
                              ? _c("span", [_vm._v("--")])
                              : _c(
                                  "el-link",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.viewCustomer(row.customer)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(row.customer.name) +
                                        "\n                "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(row.customer.phone) +
                                        "\n              "
                                    )
                                  ]
                                )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v(_vm._s(_vm.$t("driver")) + ":")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            !row.driver
                              ? _c("span", [_vm._v("--")])
                              : _c(
                                  "el-link",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.viewDriver(row.driver)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(row.driver.name) +
                                        "\n                "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(row.driver.phone) +
                                        "\n              "
                                    )
                                  ]
                                )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("b", [_vm._v(_vm._s(_vm.$t("deliveringAt")) + ":")]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.formatDateTime(row.deliveringAt)))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("b", [_vm._v(_vm._s(_vm.$t("completeAt")) + ":")]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.formatDateTime(row.completeAt)))
                        ])
                      ])
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("vehicle"), width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ul",
                      {
                        staticClass: "condition-list",
                        staticStyle: { "padding-left": "10px" }
                      },
                      [
                        _c("li", [
                          _c(
                            "span",
                            { staticStyle: { "font-weight": "bold" } },
                            [_vm._v(_vm._s(_vm.$t("style")) + ":")]
                          ),
                          _vm._v(
                            "\n            " +
                              _vm._s(row.customerVehicle.model) +
                              "\n          "
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "span",
                            { staticStyle: { "font-weight": "bold" } },
                            [_vm._v(_vm._s(_vm.$t("vehicleType")) + ":")]
                          ),
                          _vm._v(" "),
                          _c("img", {
                            staticStyle: { transform: "translateY(5px)" },
                            attrs: {
                              src: _vm.CarType[row.orderType.type].icon,
                              alt: ""
                            }
                          }),
                          _vm._v(" "),
                          _c("span", { staticStyle: { "font-size": "11px" } }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t(row.orderType.type)) +
                                "\n            "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "span",
                            { staticStyle: { "font-weight": "bold" } },
                            [_vm._v(_vm._s(_vm.$t("licensePlates")) + ":")]
                          ),
                          _vm._v(
                            "\n            " +
                              _vm._s(row.customerVehicle.licensePlates) +
                              "\n          "
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "span",
                            { staticStyle: { "font-weight": "bold" } },
                            [_vm._v(_vm._s(_vm.$t("yearProduction")) + ":")]
                          ),
                          _vm._v(
                            "\n            " +
                              _vm._s(row.customerVehicle.yearProduction) +
                              "\n          "
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "span",
                            { staticStyle: { "font-weight": "bold" } },
                            [_vm._v(_vm._s(_vm.$t("absType")) + ":")]
                          ),
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                row.customerVehicle.abs
                                  ? _vm.$t("brakeABS")
                                  : _vm.$t("brakeNormal")
                              ) +
                              "\n          "
                          )
                        ]),
                        _vm._v(" "),
                        row.customerVehicle.type == "CAR"
                          ? _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-weight": "bold" } },
                                [_vm._v(_vm._s(_vm.$t("seat")) + ":")]
                              ),
                              _vm._v(
                                "\n            " +
                                  _vm._s(row.customerVehicle.seat) +
                                  "\n          "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        row.customerVehicle.type == "CAR"
                          ? _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-weight": "bold" } },
                                [_vm._v(_vm._s(_vm.$t("gear")) + ":")]
                              ),
                              _vm._v(
                                "\n            " +
                                  _vm._s(row.customerVehicle.gear) +
                                  "\n          "
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("pickupTime") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$formatDateTime(row.time)) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("route"),
              prop: "startAddress",
              width: "200"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ul",
                      {
                        staticClass: "condition-list",
                        staticStyle: { "padding-left": "10px" }
                      },
                      [
                        _c("li", [
                          _c(
                            "span",
                            { staticStyle: { "font-weight": "bold" } },
                            [_vm._v(_vm._s(_vm.$t("distance")) + ":")]
                          ),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.formatNumberVN(row.distance)) +
                              " Km\n          "
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "span",
                            { staticStyle: { "font-weight": "bold" } },
                            [_vm._v(_vm._s(_vm.$t("from")) + ":")]
                          ),
                          _vm._v(
                            "\n            " +
                              _vm._s(row.startAddress) +
                              "\n          "
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "span",
                            { staticStyle: { "font-weight": "bold" } },
                            [_vm._v(_vm._s(_vm.$t("to")) + ":")]
                          ),
                          _vm._v(
                            "\n            " +
                              _vm._s(row.endAddress) +
                              "\n          "
                          )
                        ])
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("detail"),
              width: "210",
              "header-align": "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticClass: "pay" }, [
                      _c("li", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("moneyDistance")) + ":")
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "increase" }, [
                          _vm._v(_vm._s(_vm.formatCurrency(row.moneyDistance)))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("moneyDiscount")) + ":")
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.formatCurrency(row.moneyDiscount)))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("moneyFinal")) + ":")
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.formatCurrency(row.moneyFinal)))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("commissionDriver")) + ":")
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "increase" }, [
                          _vm._v(_vm._s(_vm.formatCurrency(row.moneyIncome)))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v(_vm._s(_vm.$t("profit")) + ":")]),
                        _vm._v(" "),
                        row.status == "COMPLETE"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatCurrency(
                                    row.moneyFinal - row.moneyIncome
                                  )
                                )
                              )
                            ])
                          : _c("span", [_vm._v("--")])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("paymentType")) + ":")
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.$t(row.paymentType)))])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("paymentStatus")) + ":")
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.$t(row.paymentStatus)))])
                      ])
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("reasonCancel") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ul",
                      {
                        staticClass: "condition-list",
                        staticStyle: { "padding-left": "10px" }
                      },
                      [
                        row.reasonCustomerCancel
                          ? _c("li", [
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.$t("reasonCustomerCancel")) + ":"
                                )
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(row.reasonCustomerCancel))
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        row.reasonAdminCancel
                          ? _c("li", [
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.$t("reasonAdminCancel")) + ":"
                                )
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(row.reasonAdminCancel))
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        row.cancelledStaff
                          ? _c("li", [
                              _c("b", [
                                _vm._v(_vm._s(_vm.$t("cancelledStaff")) + ":")
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(row.cancelledStaff.name))
                              ])
                            ])
                          : _vm._e()
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("createdAt"), prop: "dateCreated" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_vm._v(_vm._s(_vm.$formatDate(row.createdAt)))]
                }
              }
            ])
          }),
          _vm._v(" "),
          _vm.showStatusCol
            ? _c("el-table-column", {
                attrs: { label: _vm.$t("action"), width: "120" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          [
                            row.status == _vm.OrderHandleOverStatus.Pending
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", size: "mini" },
                                    on: {
                                      click: function($event) {
                                        return _vm.changeStatus(
                                          row,
                                          _vm.OrderHandleOverStatus.Approve
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("APPROVE")))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            row.status == _vm.OrderHandleOverStatus.Pending
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      "margin-left": "0",
                                      display: "block",
                                      "margin-top": "6px"
                                    },
                                    attrs: { type: "danger", size: "mini" },
                                    on: {
                                      click: function($event) {
                                        return _vm.changeStatus(
                                          row,
                                          _vm.OrderHandleOverStatus.Reject
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("REJECT")))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            row.status == _vm.OrderHandleOverStatus.Approve &&
                            _vm.visibleCancel
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      "margin-left": "0",
                                      display: "block",
                                      "margin-top": "6px"
                                    },
                                    attrs: { type: "danger", size: "mini" },
                                    on: {
                                      click: function($event) {
                                        return _vm.changeStatus(row, "CANCEL")
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("ADMIN_CANCEL")))]
                                )
                              : _vm._e()
                          ],
                          _vm._v(" "),
                          row.status == _vm.OrderHandleOverStatus.Complete ||
                          row.status == _vm.OrderHandleOverStatus.Delivering
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "column"
                                  }
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "mini" },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleViewRate(row)
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("detail")))]
                                  ),
                                  _vm._v(" "),
                                  _vm.visibleCancel
                                    ? _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            "margin-top": "8px",
                                            "margin-left": "0"
                                          },
                                          attrs: {
                                            type: "danger",
                                            size: "mini"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.adminCancel(row)
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("ADMIN_CANCEL")))]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  518118315
                )
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData
        }
      }),
      _vm._v(" "),
      _c("CustomerDialog", {
        ref: "customer-dialog",
        attrs: { dialogDisabled: true }
      }),
      _vm._v(" "),
      _c("driver-dialog", {
        ref: "driver-dialog",
        attrs: { dialogDisabled: true }
      }),
      _vm._v(" "),
      _c("images-order", { ref: "ImagesOrder" }),
      _vm._v(" "),
      _c("order-rate-modal", { ref: "orderRateModal" }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Lý do từ chối",
            visible: _vm.visibleReject,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.visibleReject = $event
            }
          }
        },
        [
          _c("label", { attrs: { for: "" } }, [_vm._v("Lý do từ chối")]),
          _vm._v(" "),
          _c("el-input", {
            staticStyle: { "margin-top": "8px" },
            attrs: { placeholder: "" },
            model: {
              value: _vm.reasonReject,
              callback: function($$v) {
                _vm.reasonReject = $$v
              },
              expression: "reasonReject"
            }
          }),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.visibleReject = false
                    }
                  }
                },
                [_vm._v("Đóng")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loadingReject, type: "danger" },
                  on: { click: _vm.rejectOrder }
                },
                [_vm._v("Từ chối đơn")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }