var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        [
          _c("el-tab-pane", { attrs: { label: "All" } }, [_c("user-table")], 1),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "Active" } },
            [_c("user-table", { attrs: { isBlock: false } })],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "Block" } },
            [_c("user-table", { attrs: { isBlock: true } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }