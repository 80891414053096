var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Order detail",
        visible: _vm.visible,
        top: "30px",
        width: "900px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "el-tabs",
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Video" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 12, offset: 0 } }, [
                    _c("h3", [_vm._v("Video before")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "center"
                        }
                      },
                      [
                        _c("video", {
                          ref: "video",
                          attrs: {
                            width: "200",
                            controls: "",
                            src: _vm.$baseUrlMedia + _vm.order.videoBefore
                          }
                        })
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12, offset: 0 } }, [
                    _c("h3", [_vm._v("Video after")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "center"
                        }
                      },
                      [
                        _c("video", {
                          ref: "video",
                          attrs: {
                            width: "200",
                            controls: "",
                            src: _vm.$baseUrlMedia + _vm.order.videoAfter
                          }
                        })
                      ]
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v(_vm._s(_vm.$t("customerRate")) + ":")
                  ]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.rate.rateComment))]),
                  _vm._v(" "),
                  _c("el-rate", {
                    attrs: { value: _vm.rate.rateStar, disabled: "" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "margin-top": "16px" } },
                [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v(_vm._s(_vm.$t("driverRate")) + ":")
                  ]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.rate.rateCommentCustomer))]),
                  _vm._v(" "),
                  _c("el-rate", {
                    attrs: { value: _vm.rate.rateStarCustomer, disabled: "" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.type != "longTerm"
            ? _c(
                "el-tab-pane",
                { attrs: { label: _vm.$t("changeLocation") } },
                [
                  _c("change-location-history-table", {
                    attrs: { data: _vm.order }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.visible = false
                }
              }
            },
            [_vm._v("Đóng")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }