"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var format = _interopRequireWildcard(require("@/utils/format"));

var orderType = _interopRequireWildcard(require("@/api/order-type"));

var _enum = require("@/utils/enum");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      configSystem: process.env.VUE_APP_CONFIG_SYSTEM,
      ORDER_TYPE: _enum.ORDER_TYPE,
      VehicleType: _enum.VehicleType,
      formData: {},
      isLoading: false,
      loadingButton: false,
      tip: {},
      dialogStatus: "",
      dialogVisible: false,
      rules: {
        minPrice: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nội dung"
        },
        kmMinPrice: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nội dung"
        },
        pricePerKM: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nội dung"
        }
      },
      tableData: []
    };
  },
  created: function created() {
    this.getData();
  },
  methods: {
    formatVND: function formatVND(n) {
      return format.formatCurrency(n);
    },
    formatNumber: function formatNumber(n) {
      return format.formatNumberVN(n);
    },
    editData: function editData() {
      var _this = this;

      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this.loadingButton = true;
          var data = {
            orderType: _this.formData
          };
          orderType.update(_this.formData.id, data).then(function () {
            _this.$message({
              type: "success",
              message: "Cập nhật nhập thành công"
            });

            _this.dialogVisible = false;

            _this.getData();
          }).finally(function () {
            _this.loadingButton = false;
          });
        }
      });
    },
    handleEdit: function handleEdit(item) {
      this.dialogStatus = "edit";
      this.dialogVisible = true;
      this.formData = (0, _objectSpread2.default)({}, item);
    },
    getData: function getData() {
      var _this2 = this;

      this.isLoading = true;
      orderType.findAll().then(function (res) {
        _this2.tableData = res.data;
      }).finally(function () {
        _this2.isLoading = false;
      });
    }
  }
};
exports.default = _default;