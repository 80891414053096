var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        [
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("promotionShopping") } },
            [
              _c("Promotion", {
                attrs: { promotionType: _vm.PromotionType.Order }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("promotionCallDriver") } },
            [
              _c("Promotion", {
                attrs: { promotionType: _vm.PromotionType.OrderTransport }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("promotionLongterm") } },
            [
              _c("Promotion", {
                attrs: { promotionType: _vm.PromotionType.OrderLongTerm }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }