var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "Add point", visible: _vm.visible, width: "400px" },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Point", prop: "amount" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: {
                      alias: "decimal",
                      groupSeparator: ".",
                      rightAlign: false,
                      autoGroup: true,
                      repeat: 20,
                      autoUnmask: true,
                      allowMinus: false
                    },
                    expression:
                      "{\n          alias: 'decimal',\n          groupSeparator: '.',\n          rightAlign: false,\n          autoGroup: true,\n          repeat: 20,\n          autoUnmask: true,\n          allowMinus: false,\n        }"
                  }
                ],
                model: {
                  value: _vm.form.amount,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "amount", $$v)
                  },
                  expression: "form.amount"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Note", prop: "note" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.note,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "note", $$v)
                  },
                  expression: "form.note"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.visible = false
                }
              }
            },
            [_vm._v("Close")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.createData }
            },
            [_vm._v("Save")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }