"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vuex = require("vuex");

var _index = _interopRequireDefault(require("@/components/Tinymce/index.vue"));

var _vue = _interopRequireDefault(require("vue"));

var _Galaries = _interopRequireDefault(require("./Galaries.vue"));

var _product = require("@/api/product");

var _SingleImage = _interopRequireDefault(require("@/components/Upload/SingleImage.vue"));

var _default = _vue.default.extend({
  components: {
    Tinymce: _index.default,
    Galaries: _Galaries.default,
    SingleImage: _SingleImage.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["token"])),
  data: function data() {
    return {
      rules: {
        title: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập tiêu đề"
        },
        body: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập mô tả"
        }
      },
      galaries: [],
      submitLoading: false,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      dialogLoading: false,
      form: {},
      dialogStatus: "",
      dialogVisible: false,
      loadingButton: false,
      stores: []
    };
  },
  methods: {
    handleUploadThumbnail: function handleUploadThumbnail(path) {
      this.$set(this.form, "thumbnail", path);
    },
    onChangeGalaries: function onChangeGalaries(fileList) {
      this.galaries = fileList;
    },
    createData: function createData() {
      var _this = this;

      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this.submitLoading = true;
          var data = {
            product: _this.form,
            galleries: _this.galaries.map(function (e) {
              return e.response.data.path;
            })
          };

          _product.productApi.create(data).then(function () {
            _this.$message({
              type: "success",
              message: "Thêm thành công"
            });

            _this.$emit("submit:success");

            _this.dialogVisible = false;
          }).finally(function () {
            _this.submitLoading = false;
          });
        }
      });
    },
    updateData: function updateData() {
      var _this2 = this;

      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this2.submitLoading = true;
          var data = {
            product: _this2.form,
            galleries: _this2.galaries.map(function (e) {
              if (e.response.data.path.includes(_this2.$baseUrlMedia)) {
                e.response.data.path = e.response.data.path.replace(_this2.$baseUrlMedia, "");
              }

              return e.response.data.path;
            })
          };

          _product.productApi.update(_this2.form.id, data).then(function () {
            _this2.$message({
              type: "success",
              message: "Cập nhật  thành công"
            });

            _this2.$emit("submit:success");

            _this2.dialogVisible = false;
          }).finally(function () {
            _this2.submitLoading = false;
          });
        }
      });
    },
    handleInitTinymce: function handleInitTinymce() {
      this.$refs["editor"].setContent("");
    },
    handleUpdate: function handleUpdate(row) {
      var _this3 = this;

      this.dialogVisible = true;
      this.dialogStatus = "update";
      this.$nextTick(function () {
        _this3.$refs["form"].clearValidate();

        _this3.form = (0, _objectSpread2.default)({}, row);
        _this3.galaries = row.productGalleries.map(function (e) {
          return {
            name: _this3.$baseUrlMedia + e.thumbnail,
            url: _this3.$baseUrlMedia + e.thumbnail,
            response: {
              data: {
                path: _this3.$baseUrlMedia + e.thumbnail
              }
            }
          };
        });

        _this3.$refs["galary"].setFileList(_this3.galaries); // this.$refs["editor"].setContent(this.form.body);

      });
    },
    handleCreate: function handleCreate() {
      var _this4 = this;

      this.dialogVisible = true;
      this.dialogStatus = "create";
      this.$nextTick(function () {
        _this4.$refs["form"].clearValidate();

        _this4.form = {};
        _this4.galaries = []; // this.$refs["editor"].setContent("");
      });
    }
  }
});

exports.default = _default;