"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _echarts = _interopRequireDefault(require("echarts"));

var _resize = _interopRequireDefault(require("./mixins/resize"));

var _format = require("@/utils/format");

var _variables = _interopRequireDefault(require("@/styles/variables.scss"));

//
//
//
//
require("echarts/theme/macarons"); // echarts theme


var animationDuration = 6000;
var _default = {
  mixins: [_resize.default],
  props: {
    attrGet: String,
    pointerName: String,
    dataChart: Array,
    format: {
      type: String,
      default: ""
    },
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "300px"
    },
    labelLine: {
      type: String,
      default: "Đơn hàng"
    },
    labelBar: {
      type: String,
      deafult: "Doanh số"
    },
    convertDataBar: {
      type: Function
    }
  },
  data: function data() {
    return {
      chart: null,
      barColor: _variables.default.primaryColor
    };
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }

    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      var _this = this;

      console.log("series", this.series);
      var self = this;
      this.chart = _echarts.default.init(this.$el, "macarons");
      this.chart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'

          }
        },
        grid: {
          top: 10,
          left: "2%",
          right: "2%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [{
          type: "category",
          data: this.dataChart.map(function (item) {
            return item.date;
          }),
          axisTick: {
            alignWithLabel: true
          }
        }],
        yAxis: [{
          type: "value",
          name: "y0",
          axisLabel: {
            formatter: "{value} đ"
          }
        }, {
          type: "value",
          name: "y1",
          interval: 5,
          axisLabel: {
            formatter: "{value} " + this.$t("order")
          }
        }],
        series: [{
          name: this.$t("sales"),
          type: "bar",
          itemStyle: {
            color: this.barColor
          },
          yAxisIndex: 0,
          data: this.dataChart.map(function (item) {
            if (typeof _this.convertDataBar == "function") {
              return _this.convertDataBar(item);
            }

            return item.total["moneyIncome"] + item.total["moneyCommission"];
          })
        }, {
          name: this.$t("order"),
          type: "line",
          yAxisIndex: 1,
          data: this.dataChart.map(function (item) {
            return item.total.quantity;
          })
        }]
      });
    }
  }
};
exports.default = _default;