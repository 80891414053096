var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        id: "driver",
        top: "30px",
        title:
          _vm.dialogStatus == "add"
            ? _vm.$t("add") + " " + _vm.$t("driver")
            : _vm.$t("update") + " " + _vm.$t("driver"),
        visible: _vm.dialogVisible,
        width: _vm.dialogWith,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading"
            }
          ]
        },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              model: {
                value: _vm.tabName,
                callback: function($$v) {
                  _vm.tabName = $$v
                },
                expression: "tabName"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: _vm.$t("info"), name: "info" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "dataForm",
                      attrs: {
                        disabled: _vm.dialogDisabled,
                        rules: _vm.rules,
                        model: _vm.tempData,
                        "label-position": "top"
                      }
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("fullname"),
                                    prop: "name"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.tempData.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.tempData, "name", $$v)
                                      },
                                      expression: "tempData.name"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("phone"),
                                    prop: "phone"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    on: {
                                      change: function($event) {
                                        return _vm.$set(
                                          _vm.tempData,
                                          "username",
                                          _vm.tempData.phone
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.tempData.phone,
                                      callback: function($$v) {
                                        _vm.$set(_vm.tempData, "phone", $$v)
                                      },
                                      expression: "tempData.phone"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("dob"),
                                    prop: "dayOfBirth"
                                  }
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      format: "dd/MM/yyyy",
                                      "value-format": "dd/MM/yyyy",
                                      type: "date",
                                      placeholder: _vm.$t("chooseDate")
                                    },
                                    model: {
                                      value: _vm.tempData.dayOfBirth,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.tempData,
                                          "dayOfBirth",
                                          $$v
                                        )
                                      },
                                      expression: "tempData.dayOfBirth"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("username"),
                                    prop: "username"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      disabled: _vm.dialogStatus != "add"
                                    },
                                    model: {
                                      value: _vm.tempData.username,
                                      callback: function($$v) {
                                        _vm.$set(_vm.tempData, "username", $$v)
                                      },
                                      expression: "tempData.username"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.dialogStatus == "add"
                            ? _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t("password"),
                                        prop: "password"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.tempData.password,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tempData,
                                              "password",
                                              $$v
                                            )
                                          },
                                          expression: "tempData.password"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("licensePlate"),
                                    prop: "licensePlate"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.tempData.licensePlate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.tempData,
                                          "licensePlate",
                                          $$v
                                        )
                                      },
                                      expression: "tempData.licensePlate"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("identification"),
                                    prop: "identification"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.tempData.identification,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.tempData,
                                          "identification",
                                          $$v
                                        )
                                      },
                                      expression: "tempData.identification"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("vehicleType"),
                                    prop: "vehicleType"
                                  }
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.tempData.vehicleType,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.tempData,
                                            "vehicleType",
                                            $$v
                                          )
                                        },
                                        expression: "tempData.vehicleType"
                                      }
                                    },
                                    _vm._l(_vm.dataVehicle, function(
                                      item,
                                      index
                                    ) {
                                      return _c(
                                        "el-checkbox",
                                        {
                                          key: index,
                                          attrs: {
                                            label: _vm.$t(item.type),
                                            value: item.check
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.handleAddVehicleType(
                                                item
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t(item.type)))]
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.dialogStatus != "add"
                            ? _c(
                                "el-col",
                                { staticStyle: { "margin-bottom": "10px" } },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("balance")) +
                                          ":\n                " +
                                          _vm._s(
                                            _vm.formatCurrency(
                                              _vm.tempData.balance
                                            )
                                          )
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Avatar", prop: "avatar" } },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      attrs: {
                                        disabled: _vm.dialogDisabled,
                                        name: "file",
                                        drag: "",
                                        "before-upload": _vm.beforeUpload,
                                        action: _vm.baseUrl + "/driver/upload",
                                        "on-success": _vm.handleAvatarSuccess,
                                        "show-file-list": false,
                                        headers: { token: _vm.token },
                                        "on-error": _vm.errorUpload
                                      }
                                    },
                                    [
                                      !_vm.tempData.avatar
                                        ? [
                                            _c("i", {
                                              staticClass: "el-icon-upload"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "el-upload__text"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$t("dragImage")
                                                    ) +
                                                    "\n                      "
                                                ),
                                                _c("em", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("clickUpload")
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          ]
                                        : _c("img", {
                                            staticClass: "avatar",
                                            attrs: {
                                              src:
                                                _vm.baseUrlMedia +
                                                _vm.tempData.avatar,
                                              alt: ""
                                            }
                                          })
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Driver license",
                                    prop: "licensePlateImage"
                                  }
                                },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      staticClass: "license-plate",
                                      attrs: {
                                        disabled: _vm.dialogDisabled,
                                        name: "file",
                                        drag: "",
                                        "before-upload": _vm.beforeUpload,
                                        action: _vm.baseUrl + "/driver/upload",
                                        "on-success":
                                          _vm.handleLicensePlateImageSuccess,
                                        "show-file-list": false,
                                        headers: { token: _vm.token },
                                        "on-error": _vm.errorUpload
                                      }
                                    },
                                    [
                                      !_vm.tempData.licensePlateImage
                                        ? [
                                            _c("i", {
                                              staticClass: "el-icon-upload"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "el-upload__text"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$t("dragImage")
                                                    ) +
                                                    "\n                      "
                                                ),
                                                _c("em", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("clickUpload")
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          ]
                                        : _c("img", {
                                            staticClass: "image",
                                            attrs: {
                                              src:
                                                _vm.baseUrlMedia +
                                                _vm.tempData.licensePlateImage,
                                              alt: ""
                                            }
                                          })
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.dialogStatus != "add"
                ? _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        lazy: "",
                        name: "order-history",
                        label: _vm.$t("orderHistory")
                      }
                    },
                    [
                      _c("OrderHistory", {
                        attrs: { driverId: _vm.tempData.id },
                        on: {
                          "update:driverId": function($event) {
                            return _vm.$set(_vm.tempData, "id", $event)
                          },
                          "update:driver-id": function($event) {
                            return _vm.$set(_vm.tempData, "id", $event)
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus != "add"
                ? _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        lazy: "",
                        name: "pay-history",
                        label: _vm.$t("payHistory")
                      }
                    },
                    [
                      _c("PayHistory", {
                        attrs: { driverId: _vm.tempData.id },
                        on: {
                          "update:driverId": function($event) {
                            return _vm.$set(_vm.tempData, "id", $event)
                          },
                          "update:driver-id": function($event) {
                            return _vm.$set(_vm.tempData, "id", $event)
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus != "add"
                ? _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        lazy: "",
                        name: "transaction-history",
                        label: _vm.$t("transactionHistory")
                      }
                    },
                    [
                      _c("TransactionHistory", {
                        attrs: { driverId: _vm.tempData.id }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("close")))]
          ),
          _vm._v(" "),
          _vm.tabName == "info"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: {
                    click: function($event) {
                      _vm.dialogStatus == "edit"
                        ? _vm.editData()
                        : _vm.createData()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("accept")))]
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }