var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: { data: _vm.tableData, border: "", fit: "" }
        },
        [
          _c("el-table-column", {
            attrs: { label: "App" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.image
                      ? _c("img", {
                          staticStyle: {
                            "margin-bottom": "4px",
                            "border-radius": "4px"
                          },
                          attrs: {
                            width: "200",
                            src: _vm.$baseUrlMedia + row.image,
                            alt: ""
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v("\n          " + _vm._s(row.app) + "\n        ")
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Visible in app" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("el-switch", {
                      attrs: {
                        value: row.isShow,
                        "active-color": "#13ce66",
                        "inactive-color": "#ff4949"
                      },
                      on: {
                        change: function($event) {
                          return _vm.handleChangeIsShow(row)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Title Vietnamese", prop: "titleVi" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Title English", prop: "titleEn" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Title Korean", prop: "titleKo" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("action") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("update")))]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("content-popup-modal", {
        ref: "dialog",
        on: { "submit:ok": _vm.getList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }