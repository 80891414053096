"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vueCountTo = _interopRequireDefault(require("vue-count-to"));

var dashboard = _interopRequireWildcard(require("@/api/dashboard"));

var _moment = _interopRequireDefault(require("moment"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      orderShopping: {},
      dataSouceDriver: {},
      dataSouceCustomer: {},
      dataOrderTransport: {},
      orderLongTerm: {},
      orderHandleOrder: {},
      today: {},
      date: [(0, _moment.default)().subtract(7, "days").format("YYYY-MM-DD"), (0, _moment.default)().format("YYYY-MM-DD")]
    };
  },
  components: {
    CountTo: _vueCountTo.default
  },
  methods: {
    getData: function getData() {
      var _this = this;

      var query = {
        from: this.date[0],
        to: this.date[1]
      };
      dashboard.today().then(function (res) {
        _this.today = res.data;
      });
      dashboard.getDashboardTransport(query).then(function (res) {
        _this.dataOrderTransport = res.data;
      });
      dashboard.getDashboardOrder(query).then(function (res) {
        _this.orderShopping = res.data;
      });
      dashboard.getDashboardDriver().then(function (res) {
        _this.dataSouceDriver = res.data;
      });
      dashboard.getDashboardCustomer(query).then(function (res) {
        _this.dataSouceCustomer = res.data;
      });
      dashboard.getDashboardOrderHandleOver().then(function (res) {
        _this.orderHandleOrder = res.data;
      });
      dashboard.getDashboardOrderLongTerm().then(function (res) {
        _this.orderLongTerm = res.data;
      });
    }
  },
  created: function created() {
    this.getData();
  }
};
exports.default = _default;