var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("title") + " " + _vm.lang, prop: "title" } },
        [
          _c("el-input", {
            model: {
              value: _vm.form["title" + _vm.lang],
              callback: function($$v) {
                _vm.$set(_vm.form, "title" + _vm.lang, $$v)
              },
              expression: "form['title'+lang]"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          attrs: {
            label: "Message " + _vm.lang + "(Notification in app)",
            prop: "message"
          }
        },
        [
          _c("el-input", {
            model: {
              value: _vm.form["message" + _vm.lang],
              callback: function($$v) {
                _vm.$set(_vm.form, "message" + _vm.lang, $$v)
              },
              expression: "form['message'+lang]"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { staticClass: "text-center", attrs: { span: 12, offset: 0 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Banner " + _vm.lang + " (Tỷ lệ: 2/1)",
                    prop: "image"
                  }
                },
                [
                  _c("SingleImage", {
                    attrs: {
                      pathImage: _vm.form["image" + _vm.lang],
                      uploadUrl: "/promotions/upload"
                    },
                    on: { "upload:success": _vm.handleUploadSuccess }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { staticClass: "text-center", attrs: { span: 12, offset: 0 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Thumbnail " + _vm.lang + " (Tỷ lệ: 1/1)",
                    prop: "image"
                  }
                },
                [
                  _c("SingleImage", {
                    attrs: {
                      pathImage: _vm.form["thumbnail" + _vm.lang],
                      uploadUrl: "/promotions/upload"
                    },
                    on: { "upload:success": _vm.handleUploadSuccessThumbnail }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          attrs: { label: _vm.$t("description") + " " + _vm.lang, prop: "body" }
        },
        [
          _c(
            "el-radio",
            {
              attrs: { label: _vm.ModeType.Post },
              model: {
                value: _vm.form.mode,
                callback: function($$v) {
                  _vm.$set(_vm.form, "mode", $$v)
                },
                expression: "form.mode"
              }
            },
            [_vm._v("Dạng bài viết")]
          ),
          _vm._v(" "),
          _c(
            "el-radio",
            {
              attrs: { label: _vm.ModeType.Link },
              model: {
                value: _vm.form.mode,
                callback: function($$v) {
                  _vm.$set(_vm.form, "mode", $$v)
                },
                expression: "form.mode"
              }
            },
            [_vm._v("Dạng liên kết ngoài")]
          ),
          _vm._v(" "),
          _vm.form.mode == _vm.ModeType.Link
            ? _c("el-input", {
                model: {
                  value: _vm.form["link" + _vm.lang],
                  callback: function($$v) {
                    _vm.$set(_vm.form, "link" + _vm.lang, $$v)
                  },
                  expression: "form['link'+lang]"
                }
              })
            : _c("Tinymce", {
                ref: "editor",
                attrs: { width: "100%", height: 400 },
                on: { onInit: _vm.handleInitTinymce },
                model: {
                  value: _vm.form["body" + _vm.lang],
                  callback: function($$v) {
                    _vm.$set(_vm.form, "body" + _vm.lang, $$v)
                  },
                  expression: "form['body'+lang]"
                }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }