var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    { attrs: { data: _vm.data.changeLocations, fit: "" } },
    [
      _c("el-table-column", {
        attrs: { label: _vm.$t("before") },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "ul",
                  {
                    staticClass: "condition-list",
                    staticStyle: { "padding-left": "10px" }
                  },
                  [
                    _c("li", [
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(_vm._s(_vm.$t("distance")) + ":")
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$formatNumberVN(row.distanceFrom)) +
                          " Km\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(_vm._s(_vm.$t("from")) + ":")
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.data.startAddress) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(_vm._s(_vm.$t("to")) + ":")
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(row.endAddressFrom) +
                          "\n        "
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("el-divider", { attrs: { direction: "horizontal" } }),
                _vm._v(" "),
                _c("ul", { staticClass: "pay" }, [
                  _c("li", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("moneyDistance")) + ":")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "increase" }, [
                      _vm._v(_vm._s(_vm.formatCurrency(row.moneyDistanceFrom)))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("moneyChangeLocation")) + ":")
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.formatCurrency(row.moneyChangeLocationFrom))
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("moneyFinal")) + ":")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.formatCurrency(row.moneyFinalFrom)))
                    ])
                  ])
                ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: _vm.$t("after") },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "ul",
                  {
                    staticClass: "condition-list",
                    staticStyle: { "padding-left": "10px" }
                  },
                  [
                    _c("li", [
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(_vm._s(_vm.$t("distance")) + ":")
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$formatNumberVN(row.distanceTo)) +
                          " Km\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(_vm._s(_vm.$t("from")) + ":")
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.data.startAddress) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(_vm._s(_vm.$t("to")) + ":")
                      ]),
                      _vm._v(
                        "\n          " + _vm._s(row.endAddressTo) + "\n        "
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("el-divider", { attrs: { direction: "horizontal" } }),
                _vm._v(" "),
                _c("ul", { staticClass: "pay" }, [
                  _c("li", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("moneyDistance")) + ":")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "increase" }, [
                      _vm._v(_vm._s(_vm.formatCurrency(row.moneyDistanceTo)))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("moneyChangeLocation")) + ":")
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.formatCurrency(row.moneyChangeLocationTo))
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("moneyFinal")) + ":")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.formatCurrency(row.moneyFinalTo)))
                    ])
                  ])
                ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: _vm.$t("status"), width: "100" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "el-tag",
                  {
                    attrs: {
                      size: "mini",
                      type:
                        _vm.tagOptions[row.status] &&
                        _vm.tagOptions[row.status].type,
                      effect:
                        _vm.tagOptions[row.status] &&
                        _vm.tagOptions[row.status].effect
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t(row.status)))]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }