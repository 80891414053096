"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _SpecialDay = _interopRequireDefault(require("./SpecialDay.vue"));

var _ConfigSpecialDay = _interopRequireDefault(require("./ConfigSpecialDay.vue"));

var _ConfigMultipleDay = _interopRequireDefault(require("./ConfigMultipleDay.vue"));

var _ConfigNormalDay = _interopRequireDefault(require("./ConfigNormalDay.vue"));

var _default = {
  components: {
    SpecialDay: _SpecialDay.default,
    ConfigSpecialDay: _ConfigSpecialDay.default,
    ConfigMultipleDay: _ConfigMultipleDay.default,
    ConfigNormalDay: _ConfigNormalDay.default
  },
  data: function data() {
    return {
      tabName: "config"
    };
  },
  created: function created() {},
  methods: {}
};
exports.default = _default;