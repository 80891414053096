var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c("div", { staticClass: "filter-item" }, [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$t("productPlaceholder") },
                nativeOn: {
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.fetchData($event)
                  }
                },
                model: {
                  value: _vm.listQuery.search,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "search", $$v)
                  },
                  expression: "listQuery.search"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: _vm.$t("status") },
                  model: {
                    value: _vm.listQuery.status,
                    callback: function($$v) {
                      _vm.$set(_vm.listQuery, "status", $$v)
                    },
                    expression: "listQuery.status"
                  }
                },
                [
                  _c("el-option", {
                    attrs: { value: null, label: _vm.$t("status") }
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.OrderStatus, function(item, index) {
                    return _c("el-option", {
                      key: item,
                      attrs: { label: _vm.$t(item), value: item }
                    })
                  })
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.fetchData }
                },
                [_vm._v(_vm._s(_vm.$t("find")))]
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { data: _vm.data, border: "", fit: "" }
        },
        [
          _c("el-table-column", {
            attrs: { label: _vm.$t("orderCode"), width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "b",
                      {
                        staticStyle: {
                          "font-size": "16px",
                          "font-weight": "bold"
                        }
                      },
                      [_vm._v(_vm._s(row.code))]
                    ),
                    _vm._v(" "),
                    _c("p", { staticStyle: { "font-size": "10px" } }, [
                      _vm._v(_vm._s(_vm.$formatDateTime(row.createdAt)))
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-tag",
                      { attrs: { type: _vm.OrderStatusColor[row.status] } },
                      [_vm._v(_vm._s(_vm.$t(row.status)))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("customer") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ul",
                      { staticStyle: { "list-style": "none", padding: "5px" } },
                      [
                        _c("li", [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(" " + _vm._s(_vm.$t("fullname")) + ": ")
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(row.customerName))])
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(" " + _vm._s(_vm.$t("phone")) + ": ")
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(row.customerPhone))])
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("customerAddress")) + ": "
                            )
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(row.customerAddress))])
                        ])
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("cost") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticClass: "list-info" }, [
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.$t("moneyTotal")) + ": ")
                        ]),
                        _c("span", { staticClass: "text-success" }, [
                          _vm._v(
                            _vm._s(_vm._f("toThousandFilter")(row.moneyTotal)) +
                              " đ"
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.$t("moneyDistance")) + ": ")
                        ]),
                        _c("span", { staticClass: "text-success" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("toThousandFilter")(row.moneyDistance)
                            ) + " đ"
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(
                            _vm._s(_vm.$t("moneyDiscount")) +
                              "\n              " +
                              _vm._s(
                                row.promotion && "(" + row.promotion.code + ")"
                              ) +
                              ": "
                          )
                        ]),
                        _c("span", { staticClass: "text-danger" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("toThousandFilter")(row.moneyDiscount)
                            ) + " đ"
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.$t("moneyFinal")) + ": ")
                        ]),
                        _c("span", { staticClass: "text-success" }, [
                          _vm._v(
                            _vm._s(_vm._f("toThousandFilter")(row.moneyFinal)) +
                              " đ"
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.$t("paymentType")) + ": ")
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t(row.paymentType)) + " ")
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.$t("paymentStatus")) + ": ")
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t(row.paymentStatus)) + " ")
                        ])
                      ])
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("detail") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ul",
                      { staticClass: "list-info" },
                      _vm._l(row.orderDetails, function(item, index) {
                        return _c("li", { key: index }, [
                          _c("div", [
                            _c(
                              "span",
                              {
                                staticClass: "name",
                                staticStyle: { "font-weight": "bold" }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.product.name) +
                                    "\n              "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "quantity" }, [
                              _vm._v(" x (" + _vm._s(item.quantity) + ") ")
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "span",
                              {
                                staticClass: "money text-success",
                                staticStyle: { "font-weight": "bold" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toThousandFilter")(item.finalPrice)
                                  )
                                )
                              ]
                            )
                          ])
                        ])
                      }),
                      0
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("action"), width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.status != _vm.OrderStatus.Cancel &&
                    row.status != _vm.OrderStatus.Complete
                      ? _c(
                          "el-select",
                          {
                            staticClass: "filter-item",
                            attrs: {
                              value: row.status,
                              placeholder: _vm.$t("status")
                            },
                            on: {
                              change: function($event) {
                                return _vm.changeStatus($event, row)
                              }
                            }
                          },
                          [
                            row.status == _vm.OrderStatus.Pending
                              ? _c(
                                  "div",
                                  _vm._l(
                                    Object.fromEntries(
                                      Object.entries(_vm.OrderStatus).filter(
                                        function(ref) {
                                          var key = ref[0]
                                          var value = ref[1]

                                          return (
                                            value != _vm.OrderStatus.Complete
                                          )
                                        }
                                      )
                                    ),
                                    function(item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: _vm.$t(item),
                                          value: item
                                        }
                                      })
                                    }
                                  ),
                                  1
                                )
                              : _c(
                                  "div",
                                  _vm._l(
                                    Object.fromEntries(
                                      Object.entries(_vm.OrderStatus).filter(
                                        function(ref) {
                                          var key = ref[0]
                                          var value = ref[1]

                                          return (
                                            value != _vm.OrderStatus.Pending
                                          )
                                        }
                                      )
                                    ),
                                    function(item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: _vm.$t(item),
                                          value: item
                                        }
                                      })
                                    }
                                  ),
                                  1
                                )
                          ]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchData
        }
      }),
      _vm._v(" "),
      _c("ProductModal", {
        ref: "product-modal",
        on: { "submit:success": _vm.fetchData }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }