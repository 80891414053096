var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticClass: "technician-table",
          attrs: { data: _vm.tableData, border: "", fit: "" }
        },
        [
          _c("el-table-column", {
            attrs: { label: _vm.$t("level"), prop: "level" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("percent"), prop: "percent" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(_vm._s(row.percent) + " " + _vm._s(" ") + " %")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("pointRef"), prop: "point" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_vm._v(_vm._s(_vm.formatNumberVN(row.point)))]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("action"),
              width: "250",
              "class-name": "small-padding fixed-width"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleEdit(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("update")))]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("affiliate-dialog", {
        ref: "dialog",
        on: { "update:success": _vm.getTableData }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }