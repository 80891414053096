"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _UploadExcel = _interopRequireDefault(require("@/components/UploadExcel"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    UploadExcel: _UploadExcel.default
  },
  data: function data() {
    return {
      column: "",
      data: []
    };
  },
  methods: {
    handleGetData: function handleGetData() {
      var _this = this;

      var res = {};
      this.data.forEach(function (element) {
        Object.keys(element).forEach(function (key) {
          if (key == _this.column) {
            res[element["Key"]] = element[key];
          }
        });
      });
      console.log("result ne", JSON.stringify(res));
    },
    onSuccess: function onSuccess(_ref) {
      var results = _ref.results;
      this.data = results;
      console.log("data ne", this.data);
    }
  }
};
exports.default = _default;