var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        { attrs: { "tab-position": "left" } },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("transport") } },
            [
              _c("order-transport", {
                attrs: { driverId: _vm.driverId, showStatusCol: false },
                on: {
                  "update:driverId": function($event) {
                    _vm.driverId = $event
                  },
                  "update:driver-id": function($event) {
                    _vm.driverId = $event
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("longTerm") } },
            [
              _c("order-longterm", {
                attrs: { driverId: _vm.driverId, showStatusCol: false },
                on: {
                  "update:driverId": function($event) {
                    _vm.driverId = $event
                  },
                  "update:driver-id": function($event) {
                    _vm.driverId = $event
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("deliveryCarOrder") } },
            [
              _c("order-car-delivery", {
                attrs: { driverId: _vm.driverId, showStatusCol: false },
                on: {
                  "update:driverId": function($event) {
                    _vm.driverId = $event
                  },
                  "update:driver-id": function($event) {
                    _vm.driverId = $event
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }