var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.listLoading,
          expression: "listLoading"
        }
      ],
      staticClass: "technician-table",
      attrs: { data: _vm.data, border: "", fit: "" }
    },
    [
      _c("el-table-column", {
        attrs: { "class-name": "text-center", type: "index", width: "50" }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { prop: "name", label: "News", width: "320" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("div", { staticClass: "info-box" }, [
                  _c("div", { staticClass: "info" }, [
                    _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(row.name))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "image",
                    attrs: { src: _vm.$baseUrlMedia + row.thumbnail, alt: "" }
                  })
                ]),
                _vm._v(" "),
                _c("span", { staticStyle: { "font-weight": "bold" } }, [
                  _vm._v(_vm._s(row.title))
                ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: _vm.$t("postSchedule") },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$formatDateTime(row.notifyAt)) +
                    "\n\n    "
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { prop: "body", label: _vm.$t("description") },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "div",
                  {
                    staticStyle: {
                      overflow: "hidden",
                      "text-overflow": "ellipsis",
                      display: "-webkit-box",
                      "-webkit-box-orient": "vertical",
                      "-webkit-line-clamp": "5"
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        row.body || row.content
                          ? _vm.stripHtml(row.body || row.content)
                          : "--"
                      )
                    )
                  ]
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: _vm.$t("createdAt") },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("div", [_vm._v(_vm._s(_vm.$formatDateTime(row.createdAt)))])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          label: _vm.$t("action"),
          width: "250",
          "class-name": "small-padding fixed-width"
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: {
                      click: function($event) {
                        return _vm.handleEdit(row)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("update")))]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "danger", size: "mini" },
                    on: {
                      click: function($event) {
                        return _vm.handleDelete(row)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("remove")))]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }