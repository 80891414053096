var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.dialogStatus == "update" ? _vm.$t("update") : _vm.$t("add"),
        visible: _vm.dialogVisible,
        width: "1200px",
        top: "15px"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading"
            }
          ]
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": "80px",
                "label-position": "top"
              }
            },
            [
              _c(
                "el-tabs",
                { attrs: { type: "card", "tab-position": "top" } },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Vi" } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("title") + " Vi",
                                            prop: "title"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: _vm.formData.title,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.formData,
                                                  "title",
                                                  $$v
                                                )
                                              },
                                              expression: "formData.title"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "text-center",
                                      attrs: { span: 24 }
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Banner Vi (Tỷ lệ: 2/1)",
                                            prop: "thumbnail"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-upload",
                                            {
                                              attrs: {
                                                name: "file",
                                                drag: "",
                                                "before-upload":
                                                  _vm.beforeUpload,
                                                action:
                                                  _vm.baseUrl +
                                                  "/banner/upload",
                                                "on-success":
                                                  _vm.handleUploadSuccess,
                                                "show-file-list": false,
                                                headers: { token: _vm.token }
                                              }
                                            },
                                            [
                                              !_vm.formData.thumbnail
                                                ? [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-upload"
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "el-upload__text"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                          Kéo thả vào đây hoặc\n                          "
                                                        ),
                                                        _c("em", [
                                                          _vm._v(
                                                            "click vào để upload banner"
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                : _c("img", {
                                                    staticStyle: {
                                                      width: "100%",
                                                      height: "100%",
                                                      "object-fit": "cover"
                                                    },
                                                    attrs: {
                                                      src:
                                                        _vm.baseUrlMedia +
                                                        _vm.formData.thumbnail,
                                                      alt: ""
                                                    }
                                                  })
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 16 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("description") + " Vi",
                                    prop: "body"
                                  }
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: _vm.ModeType.Post },
                                      model: {
                                        value: _vm.formData.mode,
                                        callback: function($$v) {
                                          _vm.$set(_vm.formData, "mode", $$v)
                                        },
                                        expression: "formData.mode"
                                      }
                                    },
                                    [_vm._v("Dạng bài viết")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: _vm.ModeType.Link },
                                      model: {
                                        value: _vm.formData.mode,
                                        callback: function($$v) {
                                          _vm.$set(_vm.formData, "mode", $$v)
                                        },
                                        expression: "formData.mode"
                                      }
                                    },
                                    [_vm._v("Dạng liên kết ngoài")]
                                  ),
                                  _vm._v(" "),
                                  _vm.formData.mode == _vm.ModeType.Link
                                    ? _c("el-input", {
                                        model: {
                                          value: _vm.formData.link,
                                          callback: function($$v) {
                                            _vm.$set(_vm.formData, "link", $$v)
                                          },
                                          expression: "formData.link"
                                        }
                                      })
                                    : _c("Tinymce", {
                                        ref: "editorVi",
                                        attrs: { width: "100%", height: 400 },
                                        on: {
                                          onInit: function($event) {
                                            return _vm.handleInitTinymce(
                                              "body",
                                              "editorVi"
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.formData.body,
                                          callback: function($$v) {
                                            _vm.$set(_vm.formData, "body", $$v)
                                          },
                                          expression: "formData.body"
                                        }
                                      })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "En" } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("title") + " En",
                                            prop: "title"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: _vm.formData.titleEn,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.formData,
                                                  "titleEn",
                                                  $$v
                                                )
                                              },
                                              expression: "formData.titleEn"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "text-center",
                                      attrs: { span: 24 }
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Banner En (Tỷ lệ: 2/1)",
                                            prop: "thumbnailEn"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-upload",
                                            {
                                              attrs: {
                                                name: "file",
                                                drag: "",
                                                "before-upload":
                                                  _vm.beforeUpload,
                                                action:
                                                  _vm.baseUrl +
                                                  "/banner/upload",
                                                "on-success":
                                                  _vm.handleUploadSuccessEn,
                                                "show-file-list": false,
                                                headers: { token: _vm.token }
                                              }
                                            },
                                            [
                                              !_vm.formData.thumbnailEn
                                                ? [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-upload"
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "el-upload__text"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                          Kéo thả vào đây hoặc\n                          "
                                                        ),
                                                        _c("em", [
                                                          _vm._v(
                                                            "click vào để upload banner"
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                : _c("img", {
                                                    staticStyle: {
                                                      width: "100%",
                                                      height: "100%",
                                                      "object-fit": "cover"
                                                    },
                                                    attrs: {
                                                      src:
                                                        _vm.baseUrlMedia +
                                                        _vm.formData
                                                          .thumbnailEn,
                                                      alt: ""
                                                    }
                                                  })
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 16 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("description") + " En",
                                    prop: "body"
                                  }
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: _vm.ModeType.Post },
                                      model: {
                                        value: _vm.formData.mode,
                                        callback: function($$v) {
                                          _vm.$set(_vm.formData, "mode", $$v)
                                        },
                                        expression: "formData.mode"
                                      }
                                    },
                                    [_vm._v("Dạng bài viết")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: _vm.ModeType.Link },
                                      model: {
                                        value: _vm.formData.mode,
                                        callback: function($$v) {
                                          _vm.$set(_vm.formData, "mode", $$v)
                                        },
                                        expression: "formData.mode"
                                      }
                                    },
                                    [_vm._v("Dạng liên kết ngoài")]
                                  ),
                                  _vm._v(" "),
                                  _vm.formData.mode == _vm.ModeType.Link
                                    ? _c("el-input", {
                                        model: {
                                          value: _vm.formData.linkEn,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "linkEn",
                                              $$v
                                            )
                                          },
                                          expression: "formData.linkEn"
                                        }
                                      })
                                    : _c("Tinymce", {
                                        ref: "editorEn",
                                        attrs: { width: "100%", height: 400 },
                                        on: {
                                          onInit: function($event) {
                                            return _vm.handleInitTinymce(
                                              "bodyEn",
                                              "editorEn"
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.formData.bodyEn,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "bodyEn",
                                              $$v
                                            )
                                          },
                                          expression: "formData.bodyEn"
                                        }
                                      })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Ko" } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("title") + " Ko",
                                            prop: "title"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: _vm.formData.titleKo,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.formData,
                                                  "titleKo",
                                                  $$v
                                                )
                                              },
                                              expression: "formData.titleKo"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "text-center",
                                      attrs: { span: 24 }
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Banner Ko (Tỷ lệ: 2/1)",
                                            prop: "thumbnailKo"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-upload",
                                            {
                                              attrs: {
                                                name: "file",
                                                drag: "",
                                                "before-upload":
                                                  _vm.beforeUpload,
                                                action:
                                                  _vm.baseUrl +
                                                  "/banner/upload",
                                                "on-success":
                                                  _vm.handleUploadSuccessKo,
                                                "show-file-list": false,
                                                headers: { token: _vm.token }
                                              }
                                            },
                                            [
                                              !_vm.formData.thumbnailKo
                                                ? [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-upload"
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "el-upload__text"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                          Kéo thả vào đây hoặc\n                          "
                                                        ),
                                                        _c("em", [
                                                          _vm._v(
                                                            "click vào để upload banner"
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                : _c("img", {
                                                    staticStyle: {
                                                      width: "100%",
                                                      height: "100%",
                                                      "object-fit": "cover"
                                                    },
                                                    attrs: {
                                                      src:
                                                        _vm.baseUrlMedia +
                                                        _vm.formData
                                                          .thumbnailKo,
                                                      alt: ""
                                                    }
                                                  })
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 16 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("description") + " Ko",
                                    prop: "body"
                                  }
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: _vm.ModeType.Post },
                                      model: {
                                        value: _vm.formData.mode,
                                        callback: function($$v) {
                                          _vm.$set(_vm.formData, "mode", $$v)
                                        },
                                        expression: "formData.mode"
                                      }
                                    },
                                    [_vm._v("Dạng bài viết")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: _vm.ModeType.Link },
                                      model: {
                                        value: _vm.formData.mode,
                                        callback: function($$v) {
                                          _vm.$set(_vm.formData, "mode", $$v)
                                        },
                                        expression: "formData.mode"
                                      }
                                    },
                                    [_vm._v("Dạng liên kết ngoài")]
                                  ),
                                  _vm._v(" "),
                                  _vm.formData.mode == _vm.ModeType.Link
                                    ? _c("el-input", {
                                        model: {
                                          value: _vm.formData.linkKo,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "linkKo",
                                              $$v
                                            )
                                          },
                                          expression: "formData.linkKo"
                                        }
                                      })
                                    : _c("Tinymce", {
                                        ref: "editorKo",
                                        attrs: { width: "100%", height: 400 },
                                        on: {
                                          onInit: function($event) {
                                            return _vm.handleInitTinymce(
                                              "bodyKo",
                                              "editorKo"
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.formData.bodyKo,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "bodyKo",
                                              $$v
                                            )
                                          },
                                          expression: "formData.bodyKo"
                                        }
                                      })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("close")))]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.submitLoading, type: "primary" },
              on: {
                click: function($event) {
                  _vm.dialogStatus == "create"
                    ? _vm.createData()
                    : _vm.updateData()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("accept")))]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }