"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConfigLongTermType = exports.CarType = exports.EnumOrderType = exports.DepositDriverType = exports.DepositDriverStatus = exports.CustomerCategory = exports.CategoryFood = exports.TransactionType = exports.NewsStoreType = exports.VehicleTypeTrans = exports.VehicleType = exports.tagOptions = exports.PARAMS = exports.ORDER_TYPE = exports.TYPE_CONTENT_DEFINE = exports.ORDER_TRANSPORT_STATUS = exports.ORDER_DELIVERY_STATUS = exports.ORDER_FOOD_STATUS = exports.STORE_TYPE = void 0;

require("core-js/modules/es6.object.freeze");

var _defineProperty2 = _interopRequireDefault(require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _VehicleTypeTrans, _Object$freeze;

var STORE_TYPE = {
  FOOD: 'Thức ăn',
  DRINK: 'Thức uống'
};
exports.STORE_TYPE = STORE_TYPE;
var ORDER_FOOD_STATUS = {
  WAITING: 'Đang đợi',
  DELIVERING: 'Đang giao',
  COMPLETE: 'Hoàn thành',
  CANCEL: 'Huỷ',
  FIND_DRIVER: 'Đang tìm tài xế',
  ACCEPT_ORDER: 'Chấp nhận đơn',
  DRIVER_CANCEL: 'Tài xế huỷ',
  CUSTOMER_CANCEL: 'Khách huỷ',
  NOT_FOUND_DRIVER: 'Không có tài xế'
};
exports.ORDER_FOOD_STATUS = ORDER_FOOD_STATUS;
var ORDER_DELIVERY_STATUS = {
  WAITING: 'Đang đợi',
  DELIVERING: 'Đang giao',
  COMPLETE: 'Hoàn thành',
  CANCEL: 'Huỷ',
  FIND_DRIVER: 'Đang tìm tài xế',
  ACCEPT_ORDER: 'Chấp nhận đơn',
  DRIVER_CANCEL: 'Tài xế huỷ',
  CUSTOMER_CANCEL: 'Khách huỷ',
  NOT_FOUND_DRIVER: 'Không có tài xế',
  PENDING: 'Chờ xử lí'
};
exports.ORDER_DELIVERY_STATUS = ORDER_DELIVERY_STATUS;
var ORDER_TRANSPORT_STATUS = {
  WAITING: 'Đang đợi',
  DELIVERING: 'Đang giao',
  COMPLETE: 'Hoàn thành',
  CANCEL: 'Huỷ',
  FIND_DRIVER: 'Đang tìm tài xế',
  ACCEPT_ORDER: 'Chấp nhận đơn',
  DRIVER_CANCEL: 'Tài xế huỷ',
  NOT_FOUND_DRIVER: 'Không có tài xế',
  CUSTOMER_CANCEL: 'Khách huỷ'
};
exports.ORDER_TRANSPORT_STATUS = ORDER_TRANSPORT_STATUS;
var TYPE_CONTENT_DEFINE = {
  ABOUT: 'Thông tin chúng tôi',
  FAQ: 'Câu hỏi thường gặp',
  HOW_TO_USE: 'Cách sử dụng',
  SECURITY: 'Bảo mật',
  TERM_CONDITION: 'Điều khoản',
  HOW_TO_DEPOSIT: 'Hướng dẫn nạp điểm',
  HOW_TO_USE_DRIVER: 'Cách sử dụng (Tài xế)',
  FAQ_DRIVER: 'Câu hỏi thường gặp (Tài xế)',
  BADGE_POINT: 'Cách tính điểm',
  JOIN_RULE: 'Thể lệ tham gia chương trình',
  INSURANCE_PACKAGE: 'Gói bảo hiểm',
  CONTENT_DRIVER: 'Quy tắc ứng xử',
  INTRODUCE_VEHICLE: 'Hướng dẫn một số dòng xe phổ biến',
  SHARE_APP_CUSTOMER: 'Nội dung share app'
};
exports.TYPE_CONTENT_DEFINE = TYPE_CONTENT_DEFINE;
var ORDER_TYPE = {
  FOOD: 'Thức ăn',
  TRANSPORT: 'Di chuyển xe máy',
  DELIVERY: 'Giao hàng',
  TRANSPORT_CAR_7: 'Xe 7 chỗ',
  TRANSPORT_CAR_4: 'Xe 4 chỗ',
  TRANSPORT_CAR_16: 'Xe 16 chỗ'
};
exports.ORDER_TYPE = ORDER_TYPE;
var PARAMS = {
  COMMISSION_TRANSPORT: 'Chiết khấu gọi xe ',
  MIN_DRIVER_BALANCE: 'Tài khoản tối thiểu',
  DISTANCE_FIND_DRIVER: 'Khoảng cách tìm tài xế',
  RATE_POINT: 'Tỷ lệ quy đổi',
  REWARD_RATE_STORE: 'Thưởng đánh giá cửa hàng',
  REWARD_RATE_ORDER: 'Thưởng đánh giá đơn hàng',
  CASHBACK_PERCENT: 'Phần trăm cashback',
  CUSTOMER_CASH_PAY_COMMISSION_ORDER_PERCENT: "Phần trăm hoàn trả tiền đơn hàng (Khách trả t.mặt)",
  CUSTOMER_ONLINE_PAY_COMMISSION_ORDER_PERCENT: "Phần trăm hoàn trả tiền đơn hàng (Khách trả ví online)"
};
exports.PARAMS = PARAMS;
var tagOptions = {
  WAITING: {
    type: 'warning',
    effect: 'plain'
  },
  ACCEPT_ORDER: {
    type: 'warning',
    effect: 'plain'
  },
  DELIVERING: {
    type: '',
    effect: 'plain'
  },
  DRIVER_CANCEL: {
    type: 'danger',
    effect: 'plain'
  },
  COMPLETE: {
    type: 'success',
    effect: 'dark'
  },
  NOT_FOUND_DRIVER: {
    type: 'danger',
    effect: 'plain'
  },
  CUSTOMER_CANCEL: {
    type: 'danger',
    effect: 'dark'
  },
  FIND_DRIVER: {
    type: 'danger',
    effect: 'dark'
  },
  CANCEL: {
    type: 'danger',
    effect: 'dark'
  },
  PENDING: {
    type: 'warning',
    effect: 'plain'
  },
  FAIL: {
    type: 'danger',
    effect: 'plain'
  },
  ADMIN_CANCEL: {
    type: 'danger',
    effect: 'dark'
  },
  NORMAL_DAY: {
    type: '',
    effect: 'dark'
  },
  SPECIAL_DAY: {
    type: 'success',
    effect: 'dark'
  },
  MULTI_DAY: {
    type: '',
    effect: 'plain'
  }
};
exports.tagOptions = tagOptions;
var VehicleType = {
  Bike: 'BIKE',
  BikeVip: 'BIKE_VIP',
  Car: 'CAR',
  CarVip: 'CAR_VIP',
  CarLuxury: 'CAR_LUXURY'
};
exports.VehicleType = VehicleType;
var VehicleTypeTrans = (_VehicleTypeTrans = {}, (0, _defineProperty2.default)(_VehicleTypeTrans, VehicleType.Bike, 'Xe máy'), (0, _defineProperty2.default)(_VehicleTypeTrans, VehicleType.BikeVip, 'Xe máy (VIP)'), (0, _defineProperty2.default)(_VehicleTypeTrans, VehicleType.Car, 'Xe hơi'), _VehicleTypeTrans);
exports.VehicleTypeTrans = VehicleTypeTrans;
var NewsStoreType = {
  Plus: 'PLUS',
  Coffee: 'COFFEE'
};
exports.NewsStoreType = NewsStoreType;
var TransactionType = {
  Deposit: 'DEPOSIT',
  Withdraw: 'WITHDRAW',
  Income: 'INCOME'
};
exports.TransactionType = TransactionType;
var CategoryFood = {
  VEGETARIAN: 'Món chay',
  SNACK: 'Ăn vặt',
  RICE: 'Cơm',
  MILK_TEA: 'Trà sữa'
};
exports.CategoryFood = CategoryFood;
var CustomerCategory = {
  ALL: 'Tất cả',
  BIRTHDAY_IN_MONTH: 'Sinh nhật trong tháng',
  OFFLINE: 'Không hoạt động',
  FEMALE: 'Nữ',
  BADGE: 'Cấp bậc'
};
exports.CustomerCategory = CustomerCategory;
var DepositDriverStatus = {
  PENDING: 'Đang xử lý',
  FAIL: 'Thất bại',
  COMPLETE: 'Thành công'
};
exports.DepositDriverStatus = DepositDriverStatus;
var DepositDriverType = {
  DRIVER: 'Nạp bằng ví điện tử ',
  STAFF: 'Nạp bằng tiền mặt'
};
exports.DepositDriverType = DepositDriverType;
var EnumOrderType = {
  BikeNormal: 'BIKE_NORMAL',
  BikeVip: 'BIKE_VIP',
  CarNormal: 'CAR_NORMAL',
  CarLuxury: 'CAR_LUXURY',
  CarSuper: 'CAR_SUPER'
};
exports.EnumOrderType = EnumOrderType;
var CarType = Object.freeze((_Object$freeze = {}, (0, _defineProperty2.default)(_Object$freeze, EnumOrderType.BikeNormal, {
  id: 0,
  title: EnumOrderType.BikeNormal,
  icon: require('@/assets/images/Moto.svg'),
  type: 'BIKE'
}), (0, _defineProperty2.default)(_Object$freeze, EnumOrderType.BikeVip, {
  id: 1,
  title: EnumOrderType.BikeVip,
  icon: require('@/assets/images/Moto.svg'),
  type: 'BIKE'
}), (0, _defineProperty2.default)(_Object$freeze, EnumOrderType.CarNormal, {
  id: 2,
  title: EnumOrderType.CarNormal,
  icon: require('@/assets/images/Car.svg'),
  type: 'CAR'
}), (0, _defineProperty2.default)(_Object$freeze, EnumOrderType.CarLuxury, {
  id: 3,
  title: EnumOrderType.CarLuxury,
  icon: require('@/assets/images/Car.svg'),
  type: 'VIP'
}), (0, _defineProperty2.default)(_Object$freeze, EnumOrderType.CarSuper, {
  id: 4,
  title: EnumOrderType.CarSuper,
  icon: require('@/assets/images/Car.svg'),
  type: 'LUXURY'
}), _Object$freeze));
exports.CarType = CarType;
var ConfigLongTermType = {
  NormalDay: 'NORMAL_DAY',
  SpecialDay: 'SPECIAL_DAY',
  MultiDay: 'MULTI_DAY'
};
exports.ConfigLongTermType = ConfigLongTermType;