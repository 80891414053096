"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetRouter = resetRouter;
exports.default = exports.asyncRoutes = exports.constantRoutes = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _layout = _interopRequireDefault(require("@/layout"));

_vue.default.use(_vueRouter.default);
/* Layout */


/* Router Modules */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = [{
  path: "/redirect",
  component: _layout.default,
  hidden: true,
  children: [{
    path: "/redirect/:path*",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/redirect/index"));
      });
    }
  }]
}, {
  path: "/login",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/index"));
    });
  },
  hidden: true
}, {
  path: "/auth-redirect",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/auth-redirect"));
    });
  },
  hidden: true
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/404"));
    });
  },
  hidden: true
}, {
  path: "/401",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/401"));
    });
  },
  hidden: true
}, {
  path: "/",
  redirect: "/dashboard",
  hidden: true
}, {
  path: "/translate",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/translate"));
    });
  },
  hidden: true,
  meta: {
    title: "Translate",
    icon: "dashboard"
  }
}, {
  path: "/dashboard",
  component: _layout.default,
  redirect: "/dashboard/index",
  permission: true,
  meta: {
    title: "Dashboard",
    icon: "dashboard",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/dashboard/index"));
      });
    },
    name: "Dashboard",
    meta: {
      permission: true,
      title: "Dashboard"
    }
  }]
}, {
  path: "/customer",
  component: _layout.default,
  redirect: "/customer/index",
  permission: true,
  meta: {
    permission: true,
    title: "customer",
    icon: "peoples"
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/customer/index"));
      });
    },
    name: "Customer",
    meta: {
      permission: true,
      title: "customer"
    }
  }]
}, {
  path: "/product",
  component: _layout.default,
  redirect: "/product/index",
  permission: true,
  meta: {
    title: "product",
    permission: true,
    icon: "tab"
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/product"));
      });
    },
    name: "product",
    meta: {
      title: "product",
      permission: true
    }
  }, {
    path: "order",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order-product"));
      });
    },
    name: "product-order",
    meta: {
      title: "order",
      permission: true
    }
  }, {
    path: "ship",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/address-ship"));
      });
    },
    name: "address-ship",
    meta: {
      permission: true,
      title: "addressShip"
    }
  }]
}, {
  path: "/order",
  component: _layout.default,
  redirect: "/order/transport",
  permission: true,
  meta: {
    title: "transport",
    permission: true,
    icon: "skill"
  },
  children: [{
    path: "transport",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/transport/index"));
      });
    },
    name: "Transport",
    meta: {
      permission: true,
      title: "order"
    }
  }, {
    path: "transport/cancel",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/transport/index"));
      });
    },
    name: "Transport-cancel",
    hidden: true,
    meta: {
      permission: true,
      title: "cancelOrder"
    }
  }, {
    path: "order-type",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/config/order-type"));
      });
    },
    name: "transport config",
    meta: {
      permission: true,
      title: "transportConfig"
    }
  }]
}, {
  path: "/order-handle-over",
  component: _layout.default,
  redirect: "/order-handle-over/index",
  permission: true,
  meta: {
    title: "deliveryCarOrder",
    icon: "car-side",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order-handle-over"));
      });
    },
    name: "order-handle-over-index",
    meta: {
      permission: true,
      title: "deliveryCarOrder"
    }
  }, {
    path: "cancel",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order-handle-over"));
      });
    },
    name: "order-delivering-cancel",
    hidden: true,
    meta: {
      permission: true,
      title: "cancelOrder"
    }
  } // {
  //   path: "booking-config",
  //   component: () => import("@/views/booking-config/index"),
  //   name: "Booking Config",
  //   meta: {
  //     title: "Cấu hình cước phí",
  //   },
  // },
  ]
}, {
  path: "/order-long-term",
  component: _layout.default,
  redirect: "/order-long-term/index",
  permission: true,
  meta: {
    permission: true,
    title: "longTerm",
    icon: "user-clock"
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order-long-term"));
      });
    },
    name: "order-long-term-index",
    meta: {
      permission: true,
      title: "order"
    }
  }, {
    path: "cancel",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order-long-term"));
      });
    },
    name: "order-long-term-cancel",
    hidden: true,
    meta: {
      permission: true,
      title: "cancelOrder"
    }
  }, {
    path: "long-term-config",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/config/long-term-package"));
      });
    },
    name: "order-long-term-config",
    meta: {
      permission: true,
      title: "longTermConfig"
    }
  }]
}, {
  path: "/drvier",
  component: _layout.default,
  redirect: "/driver/index",
  permission: true,
  meta: {
    title: "driver",
    icon: "car-fill",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/driver/index"));
      });
    },
    name: "Driver",
    meta: {
      title: "driver",
      permission: true
    }
  }, {
    path: "create",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/driver/index"));
      });
    },
    name: "diver-create",
    hidden: true,
    meta: {
      title: "create",
      permission: true
    }
  }, {
    path: "update",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/driver/index"));
      });
    },
    name: "diver-update",
    hidden: true,
    meta: {
      title: "update",
      permission: true
    }
  }, // {
  //   path: "export",
  //   component: () => import("@/views/driver/index"),
  //   name: "diver-export",
  //   hidden: true,
  //   meta: {
  //     title: "exportExcel",
  //     permission: true,
  //   },
  // },
  {
    path: "deposit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/deposit/index"));
      });
    },
    name: "Deposit",
    meta: {
      title: "DEPOSIT",
      permission: true
    }
  }, {
    path: "withdraw",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/withdraw/index"));
      });
    },
    name: "Withdraw",
    meta: {
      title: "WITHDRAW",
      permission: true
    }
  }]
}, {
  path: "/config",
  component: _layout.default,
  redirect: "/config/commission",
  meta: {
    title: "config-general",
    permission: true,
    icon: "setting-fill"
  },
  permission: true,
  children: [{
    path: "commission",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/config/commission"));
      });
    },
    name: "Config",
    meta: {
      title: "config-general",
      permission: true
    }
  }, {
    path: "affiliate",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/affiliate"));
      });
    },
    name: "Affiliate",
    meta: {
      title: "Affiliate",
      permission: true
    }
  }, {
    path: "popup-app",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/content-popup-app"));
      });
    },
    name: "popup-app",
    meta: {
      title: "Popup in app",
      permission: true
    }
  }]
}, {
  path: "/reward",
  component: _layout.default,
  redirect: "/reward/index",
  meta: {
    title: "reward",
    icon: "star",
    permission: true
  },
  permission: true,
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/reward"));
      });
    },
    name: "reward-index",
    meta: {
      title: "reward",
      permission: true
    }
  }]
}, {
  path: "/content",
  component: _layout.default,
  redirect: "/content/index",
  permission: true,
  meta: {
    title: "content",
    icon: "skill",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/content/index"));
      });
    },
    name: "Content",
    meta: {
      title: "content",
      permission: true
    }
  }]
}, {
  path: "/news",
  component: _layout.default,
  redirect: "/news/index",
  permission: true,
  meta: {
    title: "news",
    icon: "newspaper",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/news/index"));
      });
    },
    name: "news",
    meta: {
      title: "news",
      permission: true
    }
  }]
}, {
  path: "/banner",
  component: _layout.default,
  redirect: "/banner/index",
  permission: true,
  meta: {
    title: "Banner",
    icon: "international",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/banner"));
      });
    },
    name: "banner",
    meta: {
      title: "Banner",
      permission: true
    }
  }]
}, {
  path: "/promotion",
  component: _layout.default,
  redirect: "/promotion/index",
  permission: true,
  meta: {
    title: "promotion",
    icon: "guide",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/promotion"));
      });
    },
    name: "promotion",
    meta: {
      title: "promotion",
      permission: true
    }
  }]
}, {
  path: "/staff",
  component: _layout.default,
  redirect: "/staff/index",
  permission: true,
  meta: {
    title: "staff",
    icon: "user",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/user"));
      });
    },
    name: "staff-index",
    meta: {
      title: "staff",
      permission: true
    }
  }]
}, {
  path: "/op",
  component: _layout.default,
  redirect: "/op/index",
  permission: false,
  hidden: true,
  meta: {
    title: "Vận hành",
    icon: "user",
    permission: false
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/operate"));
      });
    },
    name: "staff-index",
    meta: {
      title: "Vận hành",
      permission: false
    }
  }]
}, {
  path: "/profile",
  component: _layout.default,
  redirect: "/profile/index",
  meta: {
    title: "Cá nhân",
    icon: "qq",
    permission: false
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/profile/index"));
      });
    },
    name: "Profile",
    meta: {
      permission: false,
      title: "Trang cá nhân"
    }
  }]
}, {
  path: "/feature-app",
  component: _layout.default,
  redirect: "/feature-app/index",
  meta: {
    title: "Tình năng app",
    icon: "qq",
    permission: false
  },
  hidden: true,
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/feature-app/index"));
      });
    },
    name: "feature-app",
    meta: {
      title: "Tính năng App",
      permission: false
    }
  }]
}, {
  path: "/permission",
  component: _layout.default,
  redirect: "/permission/role",
  permission: true,
  // hidden: true,
  meta: {
    title: "role",
    icon: "guide",
    permission: true
  },
  children: [{
    path: "role",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/permission/role"));
      });
    },
    name: "permission-role",
    meta: {
      permission: true,
      title: "role"
    }
  }, {
    path: "update",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/permission/role"));
      });
    },
    name: "role-update",
    hidden: true,
    meta: {
      title: "update",
      permission: true
    }
  }]
}, {
  path: "*",
  redirect: "/404",
  hidden: true
}];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */

exports.constantRoutes = constantRoutes;
var asyncRoutes = [// 404 page must be placed at the end !!!
  // {
  //   path: '*',
  //   redirect: '/404',
  //   hidden: true
  // }
];
exports.asyncRoutes = asyncRoutes;

var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: "hash",
    // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

var _default = router;
exports.default = _default;