"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.orderLongTermApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var orderLongTermApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/orderLongTerm',
      params: params
    });
  },
  approve: function approve(id) {
    return (0, _request.default)({
      url: "/orderLongTerm/".concat(id, "/approve"),
      method: 'post'
    });
  },
  reject: function reject(id) {
    return (0, _request.default)({
      url: "/orderLongTerm/".concat(id, "/reject"),
      method: 'post'
    });
  },
  rejectDriver: function rejectDriver(id) {
    return (0, _request.default)({
      url: "/orderLongTerm/".concat(id, "/driver/reject"),
      method: 'post'
    });
  },
  cancel: function cancel(id, data) {
    return (0, _request.default)({
      url: "/orderLongTerm/".concat(id, "/cancel"),
      method: 'post',
      data: data
    });
  },
  adminCancel: function adminCancel(id, data) {
    return (0, _request.default)({
      url: "/orderLongTerm/".concat(id, "/adminCancel"),
      method: 'post',
      data: data
    });
  }
};
exports.orderLongTermApi = orderLongTermApi;