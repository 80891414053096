"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.operateApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var operateApi = {
  driverFree: function driverFree() {
    return (0, _request.default)({
      url: "/operation/driver/free"
    });
  }
};
exports.operateApi = operateApi;