var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, fit: "" } },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("fullname"), width: "200px", prop: "name" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "phone", label: _vm.$t("phone") }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "orders", label: _vm.$t("order") }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("p", { staticClass: "chart-title" }, [
        _vm._v(_vm._s(_vm.$t("top5CustomerInMonth")))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }