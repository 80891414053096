var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "app-container"
    },
    [
      _c(
        "el-button",
        {
          attrs: { type: "primary", icon: "el-icon-circle-plus" },
          on: { click: _vm.handleCreate }
        },
        [_vm._v("Thêm")]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "", stripe: "" } },
        [
          _c("el-table-column", { attrs: { type: "index" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("type"), prop: "param" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("b", [_vm._v(_vm._s(_vm.$t(row.param)))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("value"), prop: "value" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("b", [_vm._v(_vm._s(row.value))]),
                    _vm._v(" " + _vm._s(_vm.renderUnit(row.param)))
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("note"), prop: "note" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("action") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleEdit(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("update")))]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            width: "500px",
            title: _vm.dialogStatus == "edit" ? _vm.$t("update") : _vm.$t("add")
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-position": "top"
              }
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("type"), prop: "param" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { disabled: _vm.dialogStatus == "edit" },
                              model: {
                                value: _vm.formData.param,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "param", $$v)
                                },
                                expression: "formData.param"
                              }
                            },
                            _vm._l(_vm.PARAMS, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item, value: index }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.renderLabel(), prop: "value" } },
                        [
                          _vm.formData.param != "HOTLINE_SOS" &&
                          _vm.formData.param != "LINK_TUTORIAL_ADD_VEHICLE"
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.formData.value,
                                    expression: "formData.value"
                                  },
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: {
                                      alias: "decimal",
                                      groupSeparator: ".",
                                      rightAlign: false,
                                      autoGroup: true,
                                      repeat: 20,
                                      autoUnmask: true,
                                      allowMinus: false
                                    },
                                    expression:
                                      "{\n                alias: 'decimal',\n                groupSeparator: '.',\n                rightAlign: false,\n                autoGroup: true,\n                repeat: 20,\n                autoUnmask: true,\n                allowMinus: false,\n              }"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.formData.value },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.formData,
                                      "value",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            : _c("el-input", {
                                attrs: { placeholder: "" },
                                model: {
                                  value: _vm.formData.value,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "value", $$v)
                                  },
                                  expression: "formData.value"
                                }
                              })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("note"), prop: "note" } },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea" },
                            model: {
                              value: _vm.formData.note,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "note", $$v)
                              },
                              expression: "formData.note"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("close")))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: {
                    click: function($event) {
                      _vm.dialogStatus == "edit"
                        ? _vm.editData()
                        : _vm.createData()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("accept")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }