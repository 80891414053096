"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _PanelGroup = _interopRequireDefault(require("./components/PanelGroup"));

var dashboard = _interopRequireWildcard(require("@/api/dashboard"));

var _date = require("@/utils/date");

var _Top5Driver = _interopRequireDefault(require("./components/rank/Top5Driver"));

var _Top5Customer = _interopRequireDefault(require("./components/rank/Top5Customer"));

var _Customer = _interopRequireDefault(require("./components/chart/Customer"));

var _OrderDelivery = _interopRequireDefault(require("./components/chart/OrderDelivery"));

var _OrderTransport = _interopRequireDefault(require("./components/chart/OrderTransport"));

var _OrderShopping = _interopRequireDefault(require("./components/chart/OrderShopping"));

var _OrderLongTerm = _interopRequireDefault(require("./components/chart/OrderLongTerm"));

var _OrderHandleOver = _interopRequireDefault(require("./components/chart/OrderHandleOver"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "DashboardAdmin",
  components: {
    PanelGroup: _PanelGroup.default,
    Top5Driver: _Top5Driver.default,
    Top5Customer: _Top5Customer.default,
    CustomerChart: _Customer.default,
    DeliveryChart: _OrderDelivery.default,
    TransportChart: _OrderTransport.default,
    OrderShoppingChart: _OrderShopping.default,
    OrderHandleOverChart: _OrderHandleOver.default,
    OrderLongTermChart: _OrderLongTerm.default
  },
  data: function data() {
    return {
      patients: [],
      babyKicks: [],
      top5BabyKick: []
    };
  },
  methods: {}
};
exports.default = _default;