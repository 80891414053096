import { render, staticRenderFns } from "./ContentPopupModal.vue?vue&type=template&id=3638b2cd&"
import script from "./ContentPopupModal.vue?vue&type=script&lang=ts&"
export * from "./ContentPopupModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3638b2cd')) {
      api.createRecord('3638b2cd', component.options)
    } else {
      api.reload('3638b2cd', component.options)
    }
    module.hot.accept("./ContentPopupModal.vue?vue&type=template&id=3638b2cd&", function () {
      api.rerender('3638b2cd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/content-popup-app/ContentPopupModal.vue"
export default component.exports