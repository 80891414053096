"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _ChangeLocationHistoryTable = _interopRequireDefault(require("./ChangeLocationHistoryTable.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    ChangeLocationHistoryTable: _ChangeLocationHistoryTable.default
  },
  props: ["type"],
  data: function data() {
    return {
      visible: false,
      video: "",
      previewModal: false,
      imageSelected: "",
      rate: {},
      order: {}
    };
  },
  watch: {
    visible: function visible(val) {
      if (!val) {
        this.$refs.video.pause();
      }
    }
  },
  methods: {
    handleOpen: function handleOpen(order, rate) {
      this.video = order.videoBefore;
      this.order = order;
      this.rate = rate;
      this.visible = true;
    },
    handleView: function handleView(item) {
      this.imageSelected = item;
      this.previewModal = true;
    }
  }
};
exports.default = _default;