"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Account = _interopRequireDefault(require("./components/Account"));

var _UserCard = _interopRequireDefault(require("./components/UserCard"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Profile",
  components: {
    Account: _Account.default,
    UserCard: _UserCard.default
  },
  data: function data() {
    return {
      user: {},
      activeTab: "account"
    };
  },
  methods: {}
};
exports.default = _default;