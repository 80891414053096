"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.popupAppApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var popupAppApi = {
  findDriver: function findDriver() {
    return (0, _request.default)({
      url: "/popUp/driver"
    });
  },
  findCustomer: function findCustomer() {
    return (0, _request.default)({
      url: "/popUp/customer"
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/popUp/".concat(id, "/update"),
      method: "post",
      data: data
    });
  }
};
exports.popupAppApi = popupAppApi;