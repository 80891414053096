"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var format = _interopRequireWildcard(require("@/utils/format"));

var commission = _interopRequireWildcard(require("@/api/commission"));

var _enum = require("@/utils/enum");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      configSystem: process.env.VUE_APP_CONFIG_SYSTEM,
      PARAMS: _enum.PARAMS,
      formData: {},
      isLoading: false,
      loadingButton: false,
      tip: {},
      dialogStatus: "",
      dialogVisible: false,
      rules: {
        value: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập giá trị"
        }
      },
      tableData: []
    };
  },
  created: function created() {
    this.getData();
  },
  methods: {
    renderUnit: function renderUnit(param) {
      var unit = null;

      switch (param) {
        case "COMMISSION_TRANSPORT":
        case "CASHBACK_PERCENT":
        case "COMMISSION_HANDLE_OVER":
        case "COMMISSION_LONG_TERM":
        case "CUSTOMER_ONLINE_PAY_COMMISSION_ORDER_PERCENT":
        case "CUSTOMER_CASH_PAY_COMMISSION_ORDER_PERCENT":
          unit = "(%)";
          break;

        case "MIN_DRIVER_BALANCE":
        case "CAR_CHANGE_LOCATION_FEE":
        case "BIKE_CHANGE_LOCATION_FEE":
          unit = "(VND)";
          break;

        case "DISTANCE_FIND_DRIVER":
          unit = "(Km)";
          break;
      }

      return unit;
    },
    renderLabel: function renderLabel() {
      var label = null;

      switch (this.formData.param) {
        case "COMMISSION_TRANSPORT":
        case "CASHBACK_PERCENT":
        case "COMMISSION_LONG_TERM":
        case "COMMISSION_HANDLE_OVER":
        case "CUSTOMER_ONLINE_PAY_COMMISSION_ORDER_PERCENT":
        case "CUSTOMER_CASH_PAY_COMMISSION_ORDER_PERCENT":
          label = this.$t("Commission") + "(%)";
          break;

        case "MIN_DRIVER_BALANCE":
        case "CAR_CHANGE_LOCATION_FEE":
        case "BIKE_CHANGE_LOCATION_FEE":
          label = this.$t("balance") + "(VND)";
          break;

        case "DISTANCE_FIND_DRIVER":
          label = this.$t("distance") + "(Km)";
          break;

        case "CASHBACK_PERCENT":
          label = "Cashback(%)";
          break;

        case "HOTLINE_SOS":
        case "HOTLINE":
          label = "Hotline";
          break;

        case "LINK_TUTORIAL_ADD_VEHICLE":
          label = "Link";
      }

      return label;
    },
    formatVND: function formatVND(n) {
      return format.formatCurrency(n);
    },
    editData: function editData() {
      var _this = this;

      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this.loadingButton = true;
          var data = {
            config: _this.formData
          };
          commission.update(_this.formData.id, data).then(function () {
            _this.$message({
              type: "success",
              message: "Cập nhật thành công"
            });

            _this.dialogVisible = false;

            _this.getData();
          }).finally(function () {
            _this.loadingButton = false;
          });
        }
      });
    },
    handleCreate: function handleCreate() {
      var _this2 = this;

      this.dialogStatus = "create";
      this.dialogVisible = true;
      this.$nextTick(function () {
        _this2.$refs["form"].resetFields();

        _this2.formData = {};
      });
    },
    createData: function createData() {
      var _this3 = this;

      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this3.loadingButton = true;
          var data = {
            config: _this3.formData
          };
          commission.create(data).then(function () {
            _this3.$message({
              type: "success",
              message: "Tạo thành công"
            });

            _this3.dialogVisible = false;

            _this3.getData();
          }).finally(function () {
            _this3.loadingButton = false;
          });
        }
      });
    },
    handleEdit: function handleEdit(item) {
      this.dialogStatus = "edit";
      this.dialogVisible = true;
      this.formData = (0, _objectSpread2.default)({}, item);
    },
    getData: function getData() {
      var _this4 = this;

      this.isLoading = true;
      commission.findAll().then(function (res) {
        _this4.tableData = res.data.data;
      }).finally(function () {
        _this4.isLoading = false;
      });
    }
  }
};
exports.default = _default;