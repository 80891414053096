var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticClass: "panel-group", attrs: { gutter: 20 } },
        [
          _c("el-col", { staticClass: "card-panel-col", attrs: { span: 8 } }, [
            _c(
              "div",
              { staticClass: "card-panel", staticStyle: { height: "120px" } },
              [
                _c(
                  "div",
                  { staticClass: "card-panel-icon-wrapper icon-money" },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "people-carry", size: "3x" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "card-panel-description" }, [
                  _c("div", { staticClass: "card-panel-text" }, [
                    _vm._v(_vm._s(_vm.$t("today")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("order")) + " shopping:")
                      ]),
                      _vm._v(" "),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.today.totalOrder,
                          duration: 3200
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("order")) +
                            " " +
                            _vm._s(_vm.$t("transport")) +
                            ":"
                        )
                      ]),
                      _vm._v(" "),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.today.totalOrderTransport,
                          duration: 3200
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("deliveryCarOrder")) + ":")
                      ]),
                      _vm._v(" "),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.today.totalOrderHandleOver,
                          duration: 3200
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("orderLongTerm")) + ":")
                      ]),
                      _vm._v(" "),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.today.totalOrderLongTerm,
                          duration: 3200
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("el-col", { staticClass: "card-panel-col", attrs: { span: 8 } }, [
            _c(
              "div",
              { staticClass: "card-panel", staticStyle: { height: "120px" } },
              [
                _c(
                  "div",
                  { staticClass: "card-panel-icon-wrapper icon-money" },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "people-carry", size: "3x" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "card-panel-description" }, [
                  _c("div", { staticClass: "card-panel-text" }, [
                    _vm._v(_vm._s(_vm.$t("driver")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v(_vm._s(_vm.$t("total")) + ":")]),
                      _vm._v(" "),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.dataSouceDriver.totalDriver,
                          duration: 3200
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v(_vm._s(_vm.$t("today")) + ":")]),
                      _vm._v(" "),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.dataSouceDriver.totalDriverToday,
                          duration: 3200
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("el-col", { staticClass: "card-panel-col", attrs: { span: 8 } }, [
            _c(
              "div",
              { staticClass: "card-panel", staticStyle: { height: "120px" } },
              [
                _c(
                  "div",
                  { staticClass: "card-panel-icon-wrapper icon-money" },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "user-tag", size: "3x" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "card-panel-description" }, [
                  _c("div", { staticClass: "card-panel-text" }, [
                    _vm._v(_vm._s(_vm.$t("customer")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v(_vm._s(_vm.$t("total")) + ":")]),
                      _vm._v(" "),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.dataSouceCustomer.totalCustomer,
                          duration: 3200
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v(_vm._s(_vm.$t("today")) + ":")]),
                      _vm._v(" "),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.dataSouceCustomer.totalCustomerToday,
                          duration: 3200
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("el-date-picker", {
        staticClass: "filter-item",
        attrs: {
          type: "daterange",
          "range-separator": "To",
          format: "dd/MM/yyyy",
          "value-format": "yyyy-MM-dd",
          "start-placeholder": "Ngày bắt đầu",
          "end-placeholder": "Ngày kết thúc"
        },
        on: { change: _vm.getData },
        model: {
          value: _vm.date,
          callback: function($$v) {
            _vm.date = $$v
          },
          expression: "date"
        }
      }),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "panel-group", attrs: { gutter: 20 } },
        [
          _c("el-col", { staticClass: "card-panel-col", attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "card-panel", staticStyle: { height: "175px" } },
              [
                _c(
                  "div",
                  { staticClass: "card-panel-icon-wrapper icon-money" },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "shopping-cart", size: "3x" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card-panel-description" },
                  [
                    _c("div", { staticClass: "card-panel-text" }, [
                      _vm._v(_vm._s(_vm.$t("order")) + " shopping")
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("total")))]),
                    _vm._v(" "),
                    _c("count-to", {
                      staticClass: "card-panel-num",
                      attrs: {
                        "start-val": 0,
                        "end-val": _vm.orderShopping.total,
                        duration: 3200
                      }
                    }),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$t("orderPending")))]),
                        _vm._v(" "),
                        _c("count-to", {
                          staticClass: "card-panel-num",
                          attrs: {
                            "start-val": 0,
                            "end-val": _vm.orderShopping.totalPending,
                            duration: 3200
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$t("orderDelivering")))]),
                        _vm._v(" "),
                        _c("count-to", {
                          staticClass: "card-panel-num",
                          attrs: {
                            "start-val": 0,
                            "end-val": _vm.orderShopping.totalDelivering,
                            duration: 3200
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$t("orderComplete")))]),
                        _vm._v(" "),
                        _c("count-to", {
                          staticClass: "card-panel-num",
                          attrs: {
                            "start-val": 0,
                            "end-val": _vm.orderShopping.totalComplete,
                            duration: 3200
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("el-col", { staticClass: "card-panel-col", attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "card-panel", staticStyle: { height: "175px" } },
              [
                _c(
                  "div",
                  { staticClass: "card-panel-icon-wrapper icon-money" },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "motorcycle", size: "3x" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card-panel-description" },
                  [
                    _c("div", { staticClass: "card-panel-text" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("order")) +
                          " " +
                          _vm._s(_vm.$t("transport")) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("total")))]),
                    _vm._v(" "),
                    _c("count-to", {
                      staticClass: "card-panel-num",
                      attrs: {
                        "start-val": 0,
                        "end-val": _vm.dataOrderTransport.totalOrderTransport,
                        duration: 3200
                      }
                    }),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$t("totalRevenue")))]),
                        _vm._v(" "),
                        _c("count-to", {
                          staticClass: "card-panel-num",
                          attrs: {
                            "start-val": 0,
                            "end-val":
                              _vm.dataOrderTransport.orderTransportCommission,
                            duration: 3200
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$t("totalIncome")))]),
                        _vm._v(" "),
                        _c("count-to", {
                          staticClass: "card-panel-num",
                          attrs: {
                            "start-val": 0,
                            "end-val":
                              _vm.dataOrderTransport.orderTransportIncome,
                            duration: 3200
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("el-col", { staticClass: "card-panel-col", attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "card-panel", staticStyle: { height: "175px" } },
              [
                _c(
                  "div",
                  { staticClass: "card-panel-icon-wrapper icon-money" },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "user-clock", size: "3x" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card-panel-description" },
                  [
                    _c("div", { staticClass: "card-panel-text" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("deliveryCarOrder")) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("total")))]),
                    _vm._v(" "),
                    _c("count-to", {
                      staticClass: "card-panel-num",
                      attrs: {
                        "start-val": 0,
                        "end-val": _vm.orderHandleOrder.totalOrderHandleOver,
                        duration: 3200
                      }
                    }),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$t("totalRevenue")))]),
                        _vm._v(" "),
                        _c("count-to", {
                          staticClass: "card-panel-num",
                          attrs: {
                            "start-val": 0,
                            "end-val":
                              _vm.orderHandleOrder.orderHandleOverCommission,
                            duration: 3200
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$t("totalIncome")))]),
                        _vm._v(" "),
                        _c("count-to", {
                          staticClass: "card-panel-num",
                          attrs: {
                            "start-val": 0,
                            "end-val":
                              _vm.orderHandleOrder.orderHandleOverIncome,
                            duration: 3200
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("el-col", { staticClass: "card-panel-col", attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "card-panel", staticStyle: { height: "175px" } },
              [
                _c(
                  "div",
                  { staticClass: "card-panel-icon-wrapper icon-money" },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "motorcycle", size: "3x" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card-panel-description" },
                  [
                    _c("div", { staticClass: "card-panel-text" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("orderLongTerm")) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("total")))]),
                    _vm._v(" "),
                    _c("count-to", {
                      staticClass: "card-panel-num",
                      attrs: {
                        "start-val": 0,
                        "end-val": _vm.orderLongTerm.totalOrderLongTerm,
                        duration: 3200
                      }
                    }),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$t("totalRevenue")))]),
                        _vm._v(" "),
                        _c("count-to", {
                          staticClass: "card-panel-num",
                          attrs: {
                            "start-val": 0,
                            "end-val":
                              _vm.orderLongTerm.orderLongTermCommission,
                            duration: 3200
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$t("totalIncome")))]),
                        _vm._v(" "),
                        _c("count-to", {
                          staticClass: "card-panel-num",
                          attrs: {
                            "start-val": 0,
                            "end-val": _vm.orderLongTerm.orderLongTermIncome,
                            duration: 3200
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }