"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.WithdrawDriverStatusTrans = exports.WithdrawDriverStatus = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.number.constructor");

var _defineProperty2 = _interopRequireDefault(require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var withdraw = _interopRequireWildcard(require("@/api/withdraw"));

var _date = require("@/utils/date");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _Dialog = _interopRequireDefault(require("./components/Dialog.vue"));

var _format = require("@/utils/format");

var _enum = require("@/utils/enum");

var _moment = _interopRequireDefault(require("moment"));

var _WithdrawDriverStatus;

var WithdrawDriverStatus = {
  Pending: "PENDING",
  Complete: "COMPLETE",
  StaffCancel: "STAFF_CANCEL",
  DriverCancel: "DRIVER_CANCEL"
};
exports.WithdrawDriverStatus = WithdrawDriverStatus;
var WithdrawDriverStatusTrans = (_WithdrawDriverStatus = {}, (0, _defineProperty2.default)(_WithdrawDriverStatus, WithdrawDriverStatus.Pending, "Pending"), (0, _defineProperty2.default)(_WithdrawDriverStatus, WithdrawDriverStatus.Complete, "Complete"), (0, _defineProperty2.default)(_WithdrawDriverStatus, WithdrawDriverStatus.StaffCancel, "Staff cancel"), (0, _defineProperty2.default)(_WithdrawDriverStatus, WithdrawDriverStatus.DriverCancel, "Driver cancel"), _WithdrawDriverStatus);
exports.WithdrawDriverStatusTrans = WithdrawDriverStatusTrans;
var _default = {
  components: {
    Pagination: _Pagination.default,
    WithdrawDialog: _Dialog.default
  },
  props: {
    driverId: {
      type: Number,
      default: null
    },
    showBtn: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      WithdrawDriverStatusTrans: WithdrawDriverStatusTrans,
      DepositDriverType: _enum.DepositDriverType,
      DepositDriverStatus: _enum.DepositDriverStatus,
      date: [(0, _moment.default)().subtract(90, "days").format("YYYY-MM-DD"), (0, _moment.default)().format("YYYY-MM-DD")],
      listLoading: false,
      total: 0,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      sum: 0,
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        isBlock: null
      },
      tableData: []
    };
  },
  created: function created() {
    this.getTableData();
  },
  watch: {
    driverId: function driverId(val) {
      this.listQuery.driverId = val;
      this.getTableData();
    }
  },
  methods: {
    typeStatus: function typeStatus(status) {
      switch (status) {
        case WithdrawDriverStatus.StaffCancel:
        case WithdrawDriverStatus.StaffCancel:
          return "danger";

        case WithdrawDriverStatus.Pending:
          return "warning";

        case WithdrawDriverStatus.Complete:
          return "success";
      }
    },
    handleApprove: function handleApprove(row) {
      var _this = this;

      this.$confirm("Confirm approve withdraw?", "Warning", {
        cancelButtonText: "No",
        confirmButtonText: "Withdraw",
        type: "warning"
      }).then(function (res) {
        _this.listLoading = true;
        withdraw.approve(row.id).then(function () {
          return _this.getTableData();
        }).finally(function () {
          return _this.listLoading = false;
        });
      });
    },
    handleCancel: function handleCancel(row) {
      var _this2 = this;

      this.$prompt("Please note", "Cancel withdraw", {
        confirmButtonText: "Cancel withdraw",
        cancelButtonText: "Close"
      }).then(function (_ref) {
        var value = _ref.value;

        if (!value) {
          return _this2.$message.error("Note is not empty!");
        }

        _this2.listLoading = true;
        withdraw.cancel(row.id, {
          reason: value
        }).then(function () {
          return _this2.getTableData();
        }).finally(function () {
          return _this2.listLoading = false;
        });
      });
    },
    changeStatus: function changeStatus(row) {
      var _this3 = this;

      this.listLoading = true;
      withdraw.update(row.id, {
        withdraw: {
          status: row.status
        }
      }).then(function () {
        _this3.$notify.success({
          title: "Thông báo",
          message: "Thao tác thành công"
        });
      }).finally(function () {
        return _this3.getTableData();
      });
    },
    formatCurrency: function formatCurrency(n) {
      return (0, _format.formatCurrency)(n);
    },
    getTableData: function getTableData() {
      var _this4 = this;

      this.listLoading = true;
      var query = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.listQuery), {}, {
        from: this.date[0],
        to: this.date[1],
        driverId: this.driverId
      });
      withdraw.findAll(query).then(function (res) {
        _this4.tableData = res.data.data;
        _this4.sum = res.data.sum;
        _this4.total = res.data.total;
      }).finally(function () {
        _this4.listLoading = false;
      });
    },
    formatDateTime: function formatDateTime(timestamp) {
      return (0, _date.formatDateTime)(timestamp);
    }
  }
};
exports.default = _default;