var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    {
      attrs: {
        action: _vm.$baseUrl + "/product/upload",
        "on-remove": _vm.handleRemove,
        multiple: "",
        "before-upload": _vm.beforeUpload,
        "file-list": _vm.fileList,
        "on-success": _vm.handleSuccess,
        drag: "",
        "on-error": _vm.onError,
        headers: { token: _vm.token },
        "list-type": "picture"
      }
    },
    [
      _vm.isProgressing
        ? _c("el-progress", {
            attrs: { type: "circle", percentage: _vm.percentUpload }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isProgressing
        ? [
            _c("i", { staticClass: "el-icon-upload" }),
            _vm._v(" "),
            _c("div", { staticClass: "el-upload__text" }, [
              _vm._v("\n      Kéo thả vào đây hoặc\n      "),
              _c("em", [_vm._v("click vào để upload")])
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }