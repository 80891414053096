var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", {
      staticStyle: { overflow: "hidden" },
      style: "height:" + _vm.height,
      domProps: { innerHTML: _vm._s(_vm.content) }
    }),
    _vm._v(" "),
    _vm.status == _vm.Status.Less
      ? _c(
          "div",
          {
            staticClass: "read-more",
            on: {
              click: function($event) {
                _vm.status = _vm.Status.More
              }
            }
          },
          [
            _c("span", [
              _vm._v("\n      " + _vm._s(_vm.$t("collapse")) + "\n    ")
            ])
          ]
        )
      : _c(
          "div",
          {
            staticClass: "read-less",
            on: {
              click: function($event) {
                _vm.status = _vm.Status.Less
              }
            }
          },
          [_c("span", [_vm._v(" " + _vm._s(_vm.$t("seeMore")))])]
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }