"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findAll = findAll;
exports.create = create;
exports.update = update;
exports.remove = remove;
exports.newsDriverApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var newsDriverApi = {
  findAll: findAll,
  create: create,
  update: update,
  remove: remove
};
exports.newsDriverApi = newsDriverApi;

function findAll(listQuery) {
  return (0, _request.default)({
    url: "/driverNews",
    params: listQuery
  });
}

function create(data) {
  return (0, _request.default)({
    url: "/driverNews",
    method: "post",
    data: data
  });
}

function update(newsId, data) {
  return (0, _request.default)({
    url: "/driverNews/".concat(newsId, "/update"),
    method: "post",
    data: data
  });
}

function remove(newsId) {
  return (0, _request.default)({
    url: "/driverNews/".concat(newsId, "/delete"),
    method: "post"
  });
}