"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _Export2Excel = require("@/vendor/Export2Excel");

var _customer = require("@/api/customer");

var _format = require("@/utils/format");

var _date = require("@/utils/date");

var _utils = require("@/utils");

var _enum = require("@/utils/enum");

var _moment = _interopRequireDefault(require("moment"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    customerId: {
      type: Number
    }
  },
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      date: [(0, _moment.default)().subtract(7, "days").format("YYYY-MM-DD"), (0, _moment.default)().format("YYYY-MM-DD")],
      listLoading: false,
      total: 0,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      listQuery: {
        page: 1,
        limit: 10
      },
      tableData: [],
      transactionType: _enum.TransactionType
    };
  },
  watch: {
    customerId: {
      handler: function handler(val) {
        if (val) {
          this.getTableData();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    stripHtml: function stripHtml(s) {
      return (0, _utils.stripHtml)(s);
    },
    formatDateTime: function formatDateTime(n) {
      return (0, _date.formatDateTime)(n);
    },
    formatNumberVN: function formatNumberVN(n) {
      return (0, _format.formatNumberVN)(n);
    },
    getTableData: function getTableData() {
      var _this = this;

      this.listLoading = true;
      this.listQuery.from = this.date ? this.date[0] : null;
      this.listQuery.to = this.date ? this.date[1] : null;

      _customer.customerApi.transaction(this.customerId, this.listQuery).then(function (res) {
        _this.tableData = res.data.data;
        _this.total = res.data.total;
      }).finally(function () {
        _this.listLoading = false;
      });
    },
    exportExcel: function exportExcel() {
      var _this2 = this;

      var header = [// this.$t("balanceType"),
      this.$t("change"), this.$t("balanceAfterChange"), this.$t("type"), this.$t("createdAt"), "Note", "Staff"];
      var data = [];
      this.tableData.forEach(function (e) {
        data.push([// this.$t(e.balanceType.toLowerCase()),
        e.title, e.balanceAfterChange, _this2.$t(e.type), (0, _date.formatDateTime)(e.createdAt), e.note, e.createdStaff ? "".concat(e.createdStaff.name, " - ").concat(e.createdStaff.phone) : ""]);
      });
      (0, _Export2Excel.export_json_to_excel)({
        data: data,
        header: header,
        filename: "Transaction History"
      });
    }
  }
};
exports.default = _default;