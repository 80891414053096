"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findAll = findAll;
exports.create = create;
exports.resetPassword = resetPassword;
exports.update = update;
exports.orderFood = orderFood;
exports.orderDelivery = orderDelivery;
exports.orderTransport = orderTransport;
exports.transaction = transaction;
exports.driverApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var driverApi = {
  addBalance: function addBalance(id, data) {
    return (0, _request.default)({
      url: "/driver/".concat(id, "/balancePromotion/add"),
      method: "post",
      data: data
    });
  },
  subtractBalance: function subtractBalance(id, data) {
    return (0, _request.default)({
      url: "/driver/".concat(id, "/balancePromotion/minus"),
      method: "post",
      data: data
    });
  },
  logout: function logout(id) {
    return (0, _request.default)({
      url: "/driver/".concat(id, "/logout"),
      method: "post"
    });
  },
  findAll: findAll,
  create: create,
  resetPassword: resetPassword,
  update: update,
  orderDelivery: orderDelivery,
  orderFood: orderFood,
  orderTransport: orderTransport,
  transaction: transaction
};
exports.driverApi = driverApi;

function findAll(listQuery) {
  return (0, _request.default)({
    url: "/driver",
    params: listQuery
  });
}

function create(data) {
  return (0, _request.default)({
    url: "/driver",
    method: "post",
    data: data
  });
}

function resetPassword(id, data) {
  return (0, _request.default)({
    url: "/driver/".concat(id, "/password/update"),
    method: "post",
    data: data
  });
}

function update(driverId, data) {
  return (0, _request.default)({
    url: "/driver/".concat(driverId, "/update"),
    method: "post",
    data: data
  });
}

function orderFood(driverId, listQuery) {
  return (0, _request.default)({
    url: "/driver/".concat(driverId, "/orderFood"),
    params: listQuery
  });
}

function orderDelivery(driverId, listQuery) {
  return (0, _request.default)({
    url: "/driver/".concat(driverId, "/orderDelivery"),
    params: listQuery
  });
}

function orderTransport(driverId, listQuery) {
  return (0, _request.default)({
    url: "/driver/".concat(driverId, "/orderTransport"),
    params: listQuery
  });
}

function transaction(driverId, listQuery) {
  return (0, _request.default)({
    url: "/driver/".concat(driverId, "/transaction"),
    params: listQuery
  });
}