var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: {
        model: _vm.formData,
        rules: _vm.rules,
        "label-width": "80px",
        "label-position": "top"
      }
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("title"), prop: "title" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.formData.title,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "title", $$v)
                              },
                              expression: "formData.title"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("postSchedule"),
                            size: "normal"
                          }
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              disabled: _vm.formData.isNotified,
                              format: "dd/MM/yyyy HH:mm",
                              type: "datetime"
                            },
                            model: {
                              value: _vm.formData.notifyAt,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "notifyAt", $$v)
                              },
                              expression: "formData.notifyAt"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Message (Notification in app)",
                            prop: "message"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "300",
                              "show-word-limit": "",
                              autosize: { minRows: 2, maxRows: 6 },
                              type: "textarea"
                            },
                            model: {
                              value: _vm.formData.message,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "message", $$v)
                              },
                              expression: "formData.message"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "text-center", attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Banner (Tỷ lệ:5/4)",
                            prop: "thumbnail"
                          }
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              attrs: {
                                name: "file",
                                drag: "",
                                "before-upload": _vm.beforeUpload,
                                action: _vm.$baseUrl + "/news/upload",
                                "on-success": _vm.handleUploadSuccess,
                                "show-file-list": false,
                                headers: { token: _vm.token }
                              }
                            },
                            [
                              !_vm.formData.thumbnail
                                ? [
                                    _c("i", { staticClass: "el-icon-upload" }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "el-upload__text" },
                                      [
                                        _vm._v(
                                          "\n                  Kéo thả vào đây hoặc\n                  "
                                        ),
                                        _c("em", [
                                          _vm._v("click vào để upload banner")
                                        ])
                                      ]
                                    )
                                  ]
                                : _c("img", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%",
                                      "object-fit": "cover"
                                    },
                                    attrs: {
                                      src:
                                        _vm.$baseUrlMedia +
                                        _vm.formData.thumbnail,
                                      alt: ""
                                    }
                                  })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 16 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("description"), prop: "body" } },
                [
                  _c("Tinymce", {
                    ref: "editor",
                    attrs: { width: "100%", height: 400 },
                    on: { onInit: _vm.handleInitTinymce },
                    model: {
                      value: _vm.formData.body,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "body", $$v)
                      },
                      expression: "formData.body"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }