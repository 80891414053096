"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findAll = findAll;
exports.update = update;
exports.cancel = cancel;
exports.adminCancel = adminCancel;

var _request = _interopRequireDefault(require("@/utils/request"));

function findAll(listQuery) {
  return (0, _request.default)({
    url: '/orderTransport',
    method: 'get',
    params: listQuery
  });
}

function update(orderId, data) {
  return (0, _request.default)({
    url: "/orderTransport/".concat(orderId, "/update"),
    method: 'post',
    data: data
  });
}

function cancel(orderId, data) {
  return (0, _request.default)({
    url: "/orderTransport/".concat(orderId, "/cancel"),
    method: 'post',
    data: data
  });
}

function adminCancel(orderId, data) {
  return (0, _request.default)({
    url: "/orderTransport/".concat(orderId, "/adminCancel"),
    method: 'post',
    data: data
  });
}