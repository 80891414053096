"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

var customer = _interopRequireWildcard(require("@/api/customer"));

var driver = _interopRequireWildcard(require("@/api/driver"));

var withdraw = _interopRequireWildcard(require("@/api/withdraw"));

var _format = require("@/utils/format");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  computed: {
    getBalance: function getBalance() {
      var _this = this;

      var res = this.drivers.find(function (item) {
        return item.id == _this.tempData.driverId;
      });
      if (res) return (0, _format.formatCurrency)(res.balance);
      return "--";
    }
  },
  data: function data() {
    return {
      dialogLoading: false,
      tempData: {},
      dialogStatus: "",
      dialogVisible: false,
      loadingButton: false,
      rules: {
        amount: {
          required: true,
          message: "Vui lòng nhập số tiền",
          trigger: "submit"
        },
        driverId: {
          required: true,
          message: "Vui lòng chọn tài xế",
          trigger: "submit"
        }
      },
      drivers: []
    };
  },
  created: function created() {
    this.getDrivers();
  },
  methods: {
    getDrivers: function getDrivers() {
      var _this2 = this;

      driver.findAll().then(function (res) {
        _this2.drivers = res.data.data;
      });
    },
    createData: function createData() {
      var _this3 = this;

      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          _this3.loadingButton = true;
          withdraw.create(_this3.tempData).then(function (res) {
            if (res.status) {
              _this3.$notify({
                title: "Rút tiền thành công",
                type: "success"
              });

              _this3.dialogVisible = false;

              _this3.$emit("create:success");

              _this3.getDrivers();
            }
          }).finally(function () {
            _this3.loadingButton = false;
          });
        }
      });
    },
    handleCreate: function handleCreate() {
      var _this4 = this;

      this.dialogVisible = true;
      this.$nextTick(function () {
        _this4.$refs["dataForm"].clearValidate();

        _this4.tempData = {};
      });
    }
  }
};
exports.default = _default;