var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("UploadExcel", { attrs: { onSuccess: _vm.onSuccess } }),
      _vm._v(" "),
      _c("el-input", {
        staticStyle: { width: "200px" },
        attrs: { placeholder: "" },
        model: {
          value: _vm.column,
          callback: function($$v) {
            _vm.column = $$v
          },
          expression: "column"
        }
      }),
      _vm._v(" "),
      _c(
        "el-button",
        {
          attrs: { type: "primary", size: "small" },
          on: { click: _vm.handleGetData }
        },
        [_vm._v("Lấy dữ liệu")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }