var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title:
          _vm.dialogStatus == "update" ? "Cập nhật sản phẩm" : "Thêm sản phẩm",
        visible: _vm.dialogVisible,
        width: "1200px",
        top: "15px"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading"
            }
          ]
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-position": "top"
              }
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Tên SP", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.form.name,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Giá gốc", prop: "originPrice" } },
                        [
                          _c("input-money", {
                            model: {
                              value: _vm.form.originPrice,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "originPrice", $$v)
                              },
                              expression: "form.originPrice"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Giá sau giảm giá",
                            prop: "finalPrice"
                          }
                        },
                        [
                          _c("input-money", {
                            model: {
                              value: _vm.form.finalPrice,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "finalPrice", $$v)
                              },
                              expression: "form.finalPrice"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Hãng", prop: "brand" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.form.brand,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "brand", $$v)
                              },
                              expression: "form.brand"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Model", prop: "model" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.form.model,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "model", $$v)
                              },
                              expression: "form.model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Warranty type",
                            prop: "warrantyType"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.form.warrantyType,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "warrantyType", $$v)
                              },
                              expression: "form.warrantyType"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Warranty period",
                            prop: "warrantyPeriod"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.form.warrantyPeriod,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "warrantyPeriod", $$v)
                              },
                              expression: "form.warrantyPeriod"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Style", prop: "style" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.form.style,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "style", $$v)
                              },
                              expression: "form.style"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "SKU", prop: "sku" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.form.sku,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "sku", $$v)
                              },
                              expression: "form.sku"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Thumbnail", prop: "thumbnail" } },
                        [
                          _c("SingleImage", {
                            attrs: {
                              uploadUrl: "/product/upload",
                              pathImage: _vm.form.thumbnail
                            },
                            on: { "upload:success": _vm.handleUploadThumbnail }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-divider"),
                      _vm._v(" "),
                      _c("Galaries", {
                        ref: "galary",
                        attrs: { onChangeGalaries: _vm.onChangeGalaries }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", placeholder: "" },
                        model: {
                          value: _vm.form.description,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "description", $$v)
                          },
                          expression: "form.description"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("Đóng")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.submitLoading, type: "primary" },
              on: {
                click: function($event) {
                  _vm.dialogStatus == "create"
                    ? _vm.createData()
                    : _vm.updateData()
                }
              }
            },
            [_vm._v("Đồng ý")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }