var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c("div", { staticClass: "filter-item" }, [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$t("productPlaceholder") },
                nativeOn: {
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.fetchData($event)
                  }
                },
                model: {
                  value: _vm.listQuery.search,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "search", $$v)
                  },
                  expression: "listQuery.search"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.fetchData }
                },
                [_vm._v(_vm._s(_vm.$t("find")))]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.handleCreate }
                },
                [_vm._v(_vm._s(_vm.$t("add")))]
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { data: _vm.data, border: "", fit: "" }
        },
        [
          _c("el-table-column", {
            attrs: { label: _vm.$t("product") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "thumbnail-box" }, [
                      _c("div", { staticClass: "info" }, [
                        _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(row.name))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "image",
                        attrs: {
                          src: _vm.$baseUrlMedia + row.thumbnail,
                          alt: ""
                        }
                      })
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("info") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("ul", [
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("originPrice")) +
                              ":\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("toThousandFilter")(row.originPrice))
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("finalPrice")) +
                              ":\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("toThousandFilter")(row.finalPrice))
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("brand")) +
                              ":\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(row.brand))])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("style")) +
                              ":\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(row.style))])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("\n              SKU:\n            ")
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(row.sku))])
                      ])
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("warranty") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("ul", [
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.$t("warrantyType")) + ": ")
                        ]),
                        _c("span", [_vm._v(_vm._s(row.warrantyType))])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.$t("warrantyPeriod")) + ": ")
                        ]),
                        _c("span", [_vm._v(_vm._s(row.warrantyPeriod))])
                      ])
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("description") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("Readmore", {
                      attrs: { content: row.description, maxHeight: 100 }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("action") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("detail")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleBlock(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("remove")))]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchData
        }
      }),
      _vm._v(" "),
      _c("ProductModal", {
        ref: "product-modal",
        on: { "submit:success": _vm.fetchData }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }