var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { data: _vm.features, border: "", fit: "" }
        },
        [
          _c("el-table-column", {
            attrs: { label: "Tính năng" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.featureTrans[row.name]) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Hiển thị" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("el-switch", {
                      attrs: {
                        "active-color": "#13ce66",
                        "inactive-color": "#ff4949"
                      },
                      on: {
                        change: function($event) {
                          return _vm.handleUpdate(row)
                        }
                      },
                      model: {
                        value: row.isShow,
                        callback: function($$v) {
                          _vm.$set(row, "isShow", $$v)
                        },
                        expression: "row.isShow"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Đang đóng" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("el-switch", {
                      attrs: {
                        "active-color": "#13ce66",
                        "inactive-color": "#ff4949"
                      },
                      on: {
                        change: function($event) {
                          return _vm.handleUpdate(row)
                        }
                      },
                      model: {
                        value: row.isComingSoon,
                        callback: function($$v) {
                          _vm.$set(row, "isComingSoon", $$v)
                        },
                        expression: "row.isComingSoon"
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }