var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "news" } },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-edit" },
              on: { click: _vm.handleAdd }
            },
            [_vm._v(_vm._s(_vm.$t("add")))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("news-table", {
        attrs: {
          listLoading: _vm.listLoading,
          handleBlock: _vm.handleBlock,
          handleEdit: _vm.handleEdit,
          handleUnBlock: _vm.handleUnBlock,
          data: _vm.tableData,
          handleDelete: _vm.handleDelete
        }
      }),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData
        }
      }),
      _vm._v(" "),
      _c("news-driver-dialog", {
        ref: "dialog",
        on: {
          "create:success": _vm.getTableData,
          "update:success": _vm.getTableData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }