"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ModeType = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vuex = require("vuex");

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var banner = _interopRequireWildcard(require("@/api/banner"));

var _utils = require("@/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var ModeType = {
  Link: "LINK",
  Post: "POST"
};
exports.ModeType = ModeType;
var _default = {
  components: {
    Tinymce: _Tinymce.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["token"])),
  data: function data() {
    return {
      ModeType: ModeType,
      rules: {
        title: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập tiêu đề"
        },
        phone: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập số điện thoại"
        },
        banner: {
          required: true,
          trigger: "submit",
          message: "Vui lòng upload banner"
        },
        body: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập mô tả"
        }
      },
      submitLoading: false,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      dialogLoading: false,
      formData: {
        type: "",
        openTime: "",
        closeTime: "",
        lat: 0,
        long: 0
      },
      dialogStatus: "",
      dialogVisible: false,
      loadingButton: false,
      isInit: {
        body: false,
        bodyEn: false,
        bodyKo: false
      }
    };
  },
  methods: {
    createData: function createData() {
      var _this = this;

      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this.submitLoading = true;
          var data = {
            banner: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this.formData), {}, {
              bodyKo: (0, _utils.convertLinkYoutube)(_this.formData.bodyKo).replaceAll("<img", '<img style="width:100%;height:auto"'),
              body: (0, _utils.convertLinkYoutube)(_this.formData.body).replaceAll("<img", '<img style="width:100%;height:auto"'),
              bodyEn: (0, _utils.convertLinkYoutube)(_this.formData.bodyEn).replaceAll("<img", '<img style="width:100%;height:auto"')
            })
          };
          banner.create(data).then(function () {
            _this.$message({
              type: "success",
              message: "Thêm banner thành công"
            });

            _this.$emit("create:success");

            _this.dialogVisible = false;
          }).finally(function () {
            _this.submitLoading = false;
          });
        }
      });
    },
    updateData: function updateData() {
      var _this2 = this;

      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this2.submitLoading = true;
          var data = {
            banner: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this2.formData), {}, {
              bodyKo: (0, _utils.convertLinkYoutube)(_this2.formData.bodyKo).replaceAll("<img", '<img style="width:100%;height:auto"'),
              body: (0, _utils.convertLinkYoutube)(_this2.formData.body).replaceAll("<img", '<img style="width:100%;height:auto"'),
              bodyEn: (0, _utils.convertLinkYoutube)(_this2.formData.bodyEn).replaceAll("<img", '<img style="width:100%;height:auto"')
            })
          };
          banner.update(_this2.formData.id, data).then(function () {
            _this2.$message({
              type: "success",
              message: "Cập nhật banner thành công"
            });

            _this2.$emit("update:success");

            _this2.dialogVisible = false;
          }).finally(function () {
            _this2.submitLoading = false;
          });
        }
      });
    },
    handleInitTinymce: function handleInitTinymce(field, ref) {
      this.isInit[field] = true;
      this.$refs[ref].setContent(this.formData[field] || "");
    },
    handleUpdate: function handleUpdate(row) {
      var _this3 = this;

      this.dialogVisible = true;
      this.dialogStatus = "update";
      this.$nextTick(function () {
        _this3.$refs["form"].clearValidate();

        _this3.formData = (0, _objectSpread2.default)({}, row);
        _this3.isInit.body && _this3.$refs["editorVi"].setContent(_this3.formData.body);
        _this3.isInit.bodyEn && _this3.$refs["editorEn"].setContent(_this3.formData.bodyEn);
        _this3.isInit.bodyKo && _this3.$refs["editorKo"].setContent(_this3.formData.bodyKo);
      });
    },
    handleCreate: function handleCreate() {
      var _this4 = this;

      this.dialogVisible = true;
      this.dialogStatus = "create";
      this.$nextTick(function () {
        _this4.$refs["form"].clearValidate();

        _this4.formData = {};
        _this4.isInit.body && _this4.$refs["editorVi"].setContent("");
        _this4.isInit.bodyEn && _this4.$refs["editorEn"].setContent("");
        _this4.isInit.bodyKo && _this4.$refs["editorKo"].setContent("");
      });
    },
    beforeUpload: function beforeUpload(file) {
      this.dialogLoading = true;
      var isJPG = file.type.includes("image");

      if (!isJPG) {
        this.dialogLoading = false;
        this.$message.error("Ảnh không đúng định dạng!");
      }

      return isJPG;
    },
    handleUploadSuccess: function handleUploadSuccess(res, file) {
      this.formData.thumbnail = res.data.path;
      this.dialogLoading = false;
    },
    handleUploadSuccessEn: function handleUploadSuccessEn(res, file) {
      this.formData.thumbnailEn = res.data.path;
      this.dialogLoading = false;
    },
    handleUploadSuccessKo: function handleUploadSuccessKo(res, file) {
      this.formData.thumbnailKo = res.data.path;
      this.dialogLoading = false;
    }
  }
};
exports.default = _default;