var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VueAutoNumeric", {
    staticClass: "el-input__inner",
    attrs: {
      value: _vm.value,
      options: Object.assign(
        {},
        {
          allowDecimalPadding: false,
          digitGroupSeparator: ",",
          decimalCharacter: "."
        },
        _vm.options
      )
    },
    on: {
      input: _vm.handleInput,
      "update:value": function($event) {
        _vm.value = $event
      }
    },
    nativeOn: {
      blur: function($event) {
        return _vm.$emit("change", _vm.value)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }