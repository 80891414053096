"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findAll = findAll;
exports.create = create;
exports.update = update;
exports.approve = approve;
exports.cancel = cancel;

var _request = _interopRequireDefault(require("@/utils/request"));

function findAll(listQuery) {
  return (0, _request.default)({
    url: "/withdrawDriver",
    method: "get",
    params: listQuery
  });
}

function create(data) {
  return (0, _request.default)({
    url: "/withdrawDriver",
    method: "post",
    data: data
  });
}

function update(id, data) {
  return (0, _request.default)({
    url: "/withdrawDriver/".concat(id, "/update"),
    method: "post",
    data: data
  });
}

function approve(id) {
  return (0, _request.default)({
    url: "/withdrawDriver/".concat(id, "/approve"),
    method: "post"
  });
}

function cancel(id, reason) {
  return (0, _request.default)({
    url: "/withdrawDriver/".concat(id, "/cancel"),
    method: "post",
    data: {
      reason: reason
    }
  });
}