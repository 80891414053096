"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _admin = _interopRequireDefault(require("./admin"));

//
//
//
//
//
//
var _default = {
  name: "Dashboard",
  components: {
    adminDashboard: _admin.default
  },
  data: function data() {
    return {
      currentRole: "adminDashboard"
    };
  }
};
exports.default = _default;