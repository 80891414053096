var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title:
          _vm.status == _vm.StatusModal.Update
            ? _vm.$t("update")
            : _vm.$t("create"),
        visible: _vm.visible,
        width: "500px",
        top: "15px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
                "label-position": "top"
              }
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("kmNumber"),
                                    prop: "km"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.form.km,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "km", $$v)
                                      },
                                      expression: "form.km"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("reward"),
                                    prop: "point"
                                  }
                                },
                                [
                                  _c("input-money", {
                                    attrs: {
                                      options: {
                                        digitGroupSeparator: ".",
                                        decimalCharacter: ","
                                      }
                                    },
                                    model: {
                                      value: _vm.form.point,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "point", $$v)
                                      },
                                      expression: "form.point"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.visible = false
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("close")))]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, type: "primary" },
              on: {
                click: function($event) {
                  _vm.status == _vm.StatusModal.Create
                    ? _vm.createData()
                    : _vm.updateData()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("accept")))]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }