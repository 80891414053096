var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "rewards" } },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleCreate } },
            [_vm._v(_vm._s(_vm.$t("add")))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "", stripe: "", fit: "" } },
        [
          _c("el-table-column", { attrs: { type: "index", label: "STT" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("kmNumber"), width: "auto" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _c("b", [_vm._v(_vm._s(_vm.formatNumberVN(row.km)))])
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("reward"), width: "auto" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [_vm._v(_vm._s(_vm.formatNumberVN(row.point)))])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("action"), width: "250" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("update")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "0" },
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleDelete(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("remove")))]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("RewardModal", {
        ref: "reward-modal",
        attrs: { rewardType: _vm.rewardType },
        on: { "submit:success": _vm.getList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }