var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }),
      _vm._v(" "),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "", stripe: "", fit: "" } },
        [
          _c("el-table-column", { attrs: { type: "index" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("title") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("b", [_vm._v(_vm._s(row.title))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("content") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("detail")))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("action") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("update")))]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title:
              _vm.dialogStatus == "update" ? _vm.$t("update") : "Thêm tin tức",
            visible: _vm.dialogVisible,
            width: "1200px",
            top: "15px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading"
                }
              ]
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.formData,
                    rules: _vm.rules,
                    "label-width": "80px",
                    "label-position": "top"
                  }
                },
                [
                  _c(
                    "el-tabs",
                    { attrs: { type: "card", "tab-position": "top" } },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "Vi" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "" + _vm.$t("title"),
                                prop: "title"
                              }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.formData.title,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "title", $$v)
                                  },
                                  expression: "formData.title"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Content VI" } },
                            [
                              _vm.formData.type != "SHARE_APP_CUSTOMER"
                                ? _c("Tinymce", {
                                    ref: "body",
                                    attrs: { width: "100%", height: 400 },
                                    on: {
                                      onInit: function($event) {
                                        return _vm.handleInitTinymce("body")
                                      }
                                    },
                                    model: {
                                      value: _vm.formData.body,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formData, "body", $$v)
                                      },
                                      expression: "formData.body"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("el-input", {
                                attrs: {
                                  autosize: { minRows: 5, maxRows: 10 },
                                  type: "textarea"
                                },
                                model: {
                                  value: _vm.formData.body,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "body", $$v)
                                  },
                                  expression: "formData.body"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "En" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "" + _vm.$t("title"),
                                prop: "titleEn"
                              }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.formData.titleEn,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "titleEn", $$v)
                                  },
                                  expression: "formData.titleEn"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Content EN" } },
                            [
                              _vm.formData.type != "SHARE_APP_CUSTOMER"
                                ? _c("Tinymce", {
                                    ref: "bodyEn",
                                    attrs: { width: "100%", height: 400 },
                                    on: {
                                      onInit: function($event) {
                                        return _vm.handleInitTinymce("bodyEn")
                                      }
                                    },
                                    model: {
                                      value: _vm.formData.bodyEn,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formData, "bodyEn", $$v)
                                      },
                                      expression: "formData.bodyEn"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("el-input", {
                                attrs: {
                                  autosize: { minRows: 5, maxRows: 10 },
                                  type: "textarea"
                                },
                                model: {
                                  value: _vm.formData.bodyEn,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "bodyEn", $$v)
                                  },
                                  expression: "formData.bodyEn"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "Ko" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "" + _vm.$t("title"),
                                prop: "titleKo"
                              }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.formData.titleKo,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "titleKo", $$v)
                                  },
                                  expression: "formData.titleKo"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Content Ko" } },
                            [
                              _vm.formData.type != "SHARE_APP_CUSTOMER"
                                ? _c("Tinymce", {
                                    ref: "bodyKo",
                                    attrs: { width: "100%", height: 400 },
                                    on: {
                                      onInit: function($event) {
                                        return _vm.handleInitTinymce("bodyKo")
                                      }
                                    },
                                    model: {
                                      value: _vm.formData.bodyKo,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formData, "bodyKo", $$v)
                                      },
                                      expression: "formData.bodyKo"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("el-input", {
                                attrs: {
                                  autosize: { minRows: 5, maxRows: 10 },
                                  type: "textarea"
                                },
                                model: {
                                  value: _vm.formData.bodyKo,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "bodyKo", $$v)
                                  },
                                  expression: "formData.bodyKo"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("close")))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.submitLoading, type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.dialogStatus == "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("accept")))]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }