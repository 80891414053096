"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/core-js/modules/es6.array.iterator.js");

require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/core-js/modules/es6.promise.js");

require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/core-js/modules/es6.object.assign.js");

require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/core-js/modules/es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

require("./styles/element-variables.scss");

require("@/plugins/google-maps");

require("@/styles/index.scss");

var _App = _interopRequireDefault(require("./App.vue"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

var _i18n = _interopRequireDefault(require("@/plugins/i18n"));

require("@/plugins/element");

require("@/plugins/OneSignal");

require("./icons");

require("./permission");

require("./utils/error-log");

require("./plugins/input-mask");

var _vueClipboard = _interopRequireDefault(require("vue-clipboard2"));

var _date = require("@/utils/date");

require("@/plugins/qr-generator");

var _InputMoney = _interopRequireDefault(require("@/components/InputMoney/InputMoney.vue"));

var filters = _interopRequireWildcard(require("./filters"));

var _fontawesomeSvgCore = require("@fortawesome/fontawesome-svg-core");

var _freeSolidSvgIcons = require("@fortawesome/free-solid-svg-icons");

var _vueFontawesome = require("@fortawesome/vue-fontawesome");

var _format = require("./utils/format");

// a modern alternative to CSS resets
// global css
// icon
// permission control
// error log
_vue.default.prototype.$formatDateTime = _date.formatDateTime;
_vue.default.prototype.$formatDate = _date.formatDate;

_vue.default.component("InputMoney", _InputMoney.default);

_fontawesomeSvgCore.library.add(_freeSolidSvgIcons.faShoppingCart, _freeSolidSvgIcons.faMotorcycle, _freeSolidSvgIcons.faTruck, _freeSolidSvgIcons.faUserTag, _freeSolidSvgIcons.faUtensils, _freeSolidSvgIcons.faPeopleCarry, _freeSolidSvgIcons.faUserSecret, _freeSolidSvgIcons.faDollarSign, _freeSolidSvgIcons.faTicketAlt, _freeSolidSvgIcons.faUserNurse, _freeSolidSvgIcons.faProcedures, _freeSolidSvgIcons.faUserClock, _freeSolidSvgIcons.faAssistiveListeningSystems);

_vue.default.component("font-awesome-icon", _vueFontawesome.FontAwesomeIcon);

_vue.default.use(_elementUi.default, {
  size: "small"
});

_vue.default.use(_vueClipboard.default); // register global utility filters


Object.keys(filters).forEach(function (key) {
  // @ts-ignore 
  _vue.default.filter(key, filters[key]);
});
_vue.default.prototype.$devMode = process.env.VUE_APP_CONFIG_SYSTEM == "true";
_vue.default.prototype.$baseUrl = process.env.VUE_APP_BASE_API;
_vue.default.prototype.$baseUrlMedia = process.env.VUE_APP_BASE_URL_MEDIA;
_vue.default.prototype.$formatNumberVN = _format.formatNumberVN;
_vue.default.config.productionTip = false;
new _vue.default({
  el: "#app",
  router: _router.default,
  store: _store.default,
  i18n: _i18n.default,
  render: function render(h) {
    return h(_App.default);
  }
});