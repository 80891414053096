"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _BarChart = _interopRequireDefault(require("../BarChart"));

var dashbord = _interopRequireWildcard(require("@/api/dashboard"));

var _moment = _interopRequireDefault(require("moment"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    BarChart: _BarChart.default
  },
  data: function data() {
    return {
      date: [(0, _moment.default)().subtract(7, "days").format("YYYY-MM-DD"), (0, _moment.default)().format("YYYY-MM-DD")],
      dataChart: []
    };
  },
  created: function created() {
    this.getDataChart();
  },
  methods: {
    getDataChart: function getDataChart() {
      var _this = this;

      var listQuery = {
        from: this.date[0],
        to: this.date[1]
      };
      dashbord.orderDeliveryChart(listQuery).then(function (res) {
        _this.dataChart = res.data.report;

        _this.$nextTick(function () {
          _this.$refs.chart.initChart();
        });
      });
    }
  }
};
exports.default = _default;