"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findAll = findAll;
exports.create = create;
exports.update = update;
exports.remove = remove;

var _request = _interopRequireDefault(require("@/utils/request"));

function findAll() {
  return (0, _request.default)({
    url: '/contentDefine'
  });
}

function create(data) {
  return (0, _request.default)({
    url: '/contentDefine',
    method: 'post',
    data: data
  });
}

function update(id, data) {
  return (0, _request.default)({
    url: "/contentDefine/".concat(id, "/update"),
    method: 'post',
    data: data
  });
}

function remove(id) {
  return (0, _request.default)({
    url: "/contentDefine/".concat(id, "/delete"),
    method: 'post'
  });
}