var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        top: "30px",
        title: _vm.$t("update") + " Affiliate",
        width: "500px",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            rules: _vm.rules,
            model: _vm.tempData,
            "label-position": "top",
            "label-width": "80px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("level") } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.tempData.level,
                  callback: function($$v) {
                    _vm.$set(_vm.tempData, "level", $$v)
                  },
                  expression: "tempData.level"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("percent"), prop: "percent" } },
            [
              _c("input-money", {
                attrs: { options: { minimumValue: 0, maximumValue: 100 } },
                model: {
                  value: _vm.tempData.percent,
                  callback: function($$v) {
                    _vm.$set(_vm.tempData, "percent", $$v)
                  },
                  expression: "tempData.percent"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("pointRef"), prop: "point" } },
            [
              _c("input-money", {
                attrs: {
                  options: { minimumValue: 1, maximumValue: 1000000000 }
                },
                model: {
                  value: _vm.tempData.point,
                  callback: function($$v) {
                    _vm.$set(_vm.tempData, "point", $$v)
                  },
                  expression: "tempData.point"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("close")))]
          ),
          _vm._v(" "),
          !_vm.dialogDisabled
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: {
                    click: function($event) {
                      _vm.dialogStatus == "edit"
                        ? _vm.editData()
                        : _vm.createData()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("accept")))]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }