"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/172_TikaTika/172admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDashboardOrder = getDashboardOrder;
exports.getDashboardTransport = getDashboardTransport;
exports.getDashboardDriver = getDashboardDriver;
exports.getDashboardOrderHandleOver = getDashboardOrderHandleOver;
exports.getDashboardOrderLongTerm = getDashboardOrderLongTerm;
exports.getDashboardCustomer = getDashboardCustomer;
exports.customerChart = customerChart;
exports.orderShoppingChart = orderShoppingChart;
exports.orderTransportChart = orderTransportChart;
exports.orderHandleOverChart = orderHandleOverChart;
exports.orderLongTermChart = orderLongTermChart;
exports.orderBookingChart = orderBookingChart;
exports.top5Driver = top5Driver;
exports.top5Customer = top5Customer;
exports.today = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

function getDashboardOrder(params) {
  return (0, _request.default)({
    url: "/dashboard/order",
    method: "get",
    params: params
  });
}

var today = function today() {
  return (0, _request.default)({
    url: "/dashboard/today"
  });
};

exports.today = today;

function getDashboardTransport(params) {
  return (0, _request.default)({
    url: "/dashboard/orderTransport",
    method: "get",
    params: params
  });
}

function getDashboardDriver() {
  return (0, _request.default)({
    url: "/dashboard/driver",
    method: "get"
  });
}

function getDashboardOrderHandleOver(params) {
  return (0, _request.default)({
    url: "/dashboard/orderHandleOver",
    method: "get",
    params: params
  });
}

function getDashboardOrderLongTerm(params) {
  return (0, _request.default)({
    url: "/dashboard/orderLongTerm",
    method: "get",
    params: params
  });
}

function getDashboardCustomer(params) {
  return (0, _request.default)({
    url: "/dashboard/customer",
    method: "get",
    params: params
  });
}

function customerChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/customer/last30",
    method: "get",
    params: listQuery
  });
}

function orderShoppingChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/order/last30",
    method: "get",
    params: listQuery
  });
}

function orderTransportChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/orderTransport/last30",
    method: "get",
    params: listQuery
  });
}

function orderHandleOverChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/orderHandleOver/last30",
    method: "get",
    params: listQuery
  });
}

function orderLongTermChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/orderLongTerm/last30",
    method: "get",
    params: listQuery
  });
}

function orderBookingChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/bookingTransport/last30",
    method: "get",
    params: listQuery
  });
}

function top5Driver() {
  return (0, _request.default)({
    url: "/dashboard/driver/top5",
    method: "get"
  });
}

function top5Customer() {
  return (0, _request.default)({
    url: "/dashboard/customer/top5",
    method: "get"
  });
}