var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        top: "30px",
        title:
          _vm.dialogStatus == "add" ? "Thêm nhân viên" : "Cập nhật nhân viên",
        visible: _vm.dialogVisible,
        width: "500px",
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading"
            }
          ]
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                disabled: _vm.dialogDisabled,
                rules: _vm.rules,
                model: _vm.tempData,
                "label-position": "left"
              }
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Name", prop: "name" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.tempData.name,
                              callback: function($$v) {
                                _vm.$set(_vm.tempData, "name", $$v)
                              },
                              expression: "tempData.name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Username", prop: "username" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.tempData.username,
                              callback: function($$v) {
                                _vm.$set(_vm.tempData, "username", $$v)
                              },
                              expression: "tempData.username"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.dialogStatus != "edit"
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("password"),
                                prop: "password"
                              }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.tempData.password,
                                  callback: function($$v) {
                                    _vm.$set(_vm.tempData, "password", $$v)
                                  },
                                  expression: "tempData.password"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Email", prop: "email" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.tempData.email,
                              callback: function($$v) {
                                _vm.$set(_vm.tempData, "email", $$v)
                              },
                              expression: "tempData.email"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("phone"), prop: "phone" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.tempData.phone,
                              callback: function($$v) {
                                _vm.$set(_vm.tempData, "phone", $$v)
                              },
                              expression: "tempData.phone"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Rule" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "" },
                              model: {
                                value: _vm.roleId,
                                callback: function($$v) {
                                  _vm.roleId = $$v
                                },
                                expression: "roleId"
                              }
                            },
                            _vm._l(_vm.roles, function(role) {
                              return _c("el-option", {
                                key: role.id,
                                attrs: { label: role.name, value: role.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          false
            ? _c(
                "el-upload",
                {
                  staticClass: "avatar-uploader",
                  attrs: {
                    disabled: _vm.dialogDisabled,
                    action: _vm.baseUrl + "/users/avatar/upload",
                    "show-file-list": false,
                    name: "avatar",
                    "on-success": _vm.handleAvatarSuccess,
                    "before-upload": _vm.beforeAvatarUpload,
                    headers: { token: _vm.token }
                  }
                },
                [
                  _vm.tempData.avatar
                    ? _c("img", {
                        staticClass: "avatar",
                        attrs: { src: _vm.baseUrlMedia + _vm.tempData.avatar }
                      })
                    : _c("i", {
                        staticClass: "el-icon-plus avatar-uploader-icon"
                      })
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("close")))]
          ),
          _vm._v(" "),
          !_vm.dialogDisabled
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: {
                    click: function($event) {
                      _vm.dialogStatus == "edit"
                        ? _vm.editData()
                        : _vm.createData()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("accept")))]
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }